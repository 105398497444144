<template lang="pug">
    v-container(fluid class="pa-0 h-auto")
        v-row(justify="center" class="h-100")
            v-col(sm="4" class="py-0 px-5 blue-700")
                h3(class="text-subtitle-1 yellow-300--text mt-4 mb-2") ¿Qué hace? 
                p(class="white--text mb-4") descripcion descripcion descripcion
                h3(class="text-subtitle-1 yellow-300--text mb-2") ¿Qué problema soluciona?
                p(class="white--text") descripcion descripcion descripcion

            v-col(sm="8" class="align-self-stretch" class="pl-3 pr-6" style="background: rgba(221, 221, 221, 0.5);")
                ValidationObserver( ref="observer")
                    v-card(class="mb-5")
                        v-card-text(v-if="isRequiredFilter")
                            v-row(align="center")
                                v-col(cols='12')
                                    v-card(outline)
                                        v-card-text
                                            v-row(align="center")
                                                v-col(cols='12' md='6')
                                                    //- ValidationProvider(v-slot='{ errors }' name='estado' rules='required')
                                                        // :rules="isRequiredForm==true?required:{}"
                                                    p( class="text-title font-weight-bold blue-500--text mt-5") División Estatal
                                                    v-select(
                                                        label="Selecciona uno o varios estados"
                                                        item-text="NomEnt"
                                                        @blur="fetchCity()"
                                                        :items="states"
                                                        :loading="loading_data"
                                                        :disabled="loading_data"
                                                        v-model='step_data.NomEnt'
                                                        color="primary"
                                                        chips multiple)

                                                v-col(cols='12' md='6')
                                                    ValidationProvider(v-slot='{ errors }' name='ciudad' rules="")
                                                        p( class="text-title font-weight-bold blue-500--text mt-5") División Municipal
                                                        v-select(
                                                            label="Selecciona uno o varios municipios"
                                                            :items="municipios"
                                                            v-model='step_data.NomMun',
                                                            :loading="loading_municipios"
                                                            :disabled="!municipios.length"
                                                            color="primary"
                                                            class="mt-2"
                                                            item-text="NomMun"
                                                            :error-messages='errors'
                                                            chips multiple)
                                                            template(v-slot:prepend-item)
                                                                v-list-item(ripple @click="toggleMun")
                                                                    v-list-item-action
                                                                        v-icon(:color="step_data.NomMun.length > 0 ? 'blue-500' : ''") {{ iconSelectItemsMun }}
                                                                    v-list-item-content
                                                                        v-list-item-title Seleccionar todo
                                                                v-divider(class="mt-2")
                                                            
                            v-row(align="center")
                                v-col(cols="12")
                                    v-card(outline)
                                        v-card-text
                                            v-row(align="center")
                                                v-col(cols='10' md='5')
                                                    p( class="text-title font-weight-bold blue-500--text mt-5") Tipo de ciudad
                                                    p( class="text-caption text-justify") Esta clasificación de ciudades corresponde a la  delimitación y descripción  del sistema urbano nacional 2018, elaborado por SEDESOL, CONAPO e INEGI, permiten observar las diferentes formas del proceso de urbanización.
                                                    v-select(
                                                        label="Selecciona uno o varios tipos de ciudad" 
                                                        :items="type_of_city"
                                                        v-model='step_data.type_of_city',
                                                        :loading="loading_data"
                                                        :disabled="loading_data"
                                                        color="primary"
                                                        chips multiple)
                                                v-col(cols='2' md='1')
                                                    v-tooltip( top class="d-inline")
                                                        template(v-slot:activator='{ on, attrs }')
                                                            v-btn(icon color='primary' v-bind='attrs' v-on='on')
                                                                v-icon mdi-help-circle
                                                        p( class="text-caption text-justify") 
                                                            | <b>*Zona metropolitana:</b> agrupación en una sola unidad de municipios completos que comparten una ciudad central y están altamente interrelacionados funcionalmente. También se consideran a los centros urbanos mayores a un millón de habitantes aunque no hayan rebasado su límite municipal y a los centros urbanos de las zonas metropolitanas transfronterizas mayores a 250 mil habitantes.
                                                            | <br/>
                                                            | <br/>
                                                            | <b>*Conurbación:</b> conformación urbana resultado de la continuidad física entre dos o más localidades geoestadísticas o centros urbanos, constituyendo una sola unidad urbana de por lo menos 15 mil habitantes. Pueden ser intermunicipales e interestatales cuando su población oscila entre 15 mil y 49 mil 999 habitantes e intramunicipales aun superando este rango poblacional.
                                                            | <br/>
                                                            | <br/> <b>*Centros urbanos:</b> ciudades con 15 mil o más habitantes, que no reúnen características de conurbación o zona metropolitana.
                                
                                
                                                v-col(cols='12' md='6')
                                                    p( class="text-title font-weight-bold blue-500--text mt-5") Clusters de ciudades
                                                    v-select(
                                                        label='Selecciona uno o varios cluster de ciudades'
                                                        v-model='step_data.clusters_category'
                                                        :items="clusters"
                                                        :loading="loading_data"
                                                        :disabled="!clusters.length"
                                                        item-text="ClusterAD"
                                                        chips multiple)
                                                    p( class="text-caption text-justify")
                                                        | Analitica Digital calculó 104 cluster territoriales que comparten características físicas, demográficas, económicas y vías de comunicación entre ciudades que se encuentran relacionadas funcionalmente, y cualquier cambio significativo en alguna de ellas propicia, en mayor o menor medida, alteraciones en las otras. El nombre de cada cluster está asignado por el nombre la ciudad principal.

                
                            v-row(align="center")
                                v-col 
                                    v-card
                                        v-card-text
                                            v-row
                                                v-col(cols='12' md='6')
                                                    p( class="text-title font-weight-bold blue-500--text ") División Coppel
                                                    p( class="text-caption")
                                                        | Grandes extensiones territoriales en las que se divide la república Mexicana para Coppel
                                                    v-select(
                                                        label='Selecciona una o varias divisiones'
                                                        v-model='step_data.DivCoppel'
                                                        item-text="DivisionCoppelAD"
                                                        :loading="loading_data"
                                                        :disabled="loading_data"
                                                        :items="division"
                                                        chips multiple)
                                                v-col(cols='12' md='6')
                                                    p( class="text-title font-weight-bold blue-500--text ") División o regiones  AMVO
                                                    p( class="text-caption")
                                                        | Regiones en las que se divide el país de acuerdo a la Asociación Mexicana de Venta Online,  base Nielsen. 

                                                    v-select(
                                                        label='Selecciona una o varias regiones'
                                                        v-model='step_data.RegNielsen'
                                                        item-text="RegNielsenAD"
                                                        :items="regions"
                                                        :loading="loading_data"
                                                        :disabled="loading_data"
                                                        chips multiple)
                v-row(class="mt-5")
                    v-col(class="d-flex")
                        v-btn(color='grey' dark  tile elevation @click='handlePrevStep')  Regresar
                        v-spacer
                        v-btn( color="primary" @click="handleNextStep"  tile elevation="0") Siguiente
        v-dialog( v-model="dialog" max-width="290" )
            v-card
                v-card-title( class="headline" ) ¡Aviso!
                v-card-text 
                    p( class="black-500--text ") 
                        | Para continuar es necesario haber seleccionado al menos un campo del formulario.
                v-card-actions
                    v-spacer
                    v-btn( color="blue darken-1" text @click="dialog = false") Cerrar

</template>

<script>

import  SegmenterService from '../../../../services/SegmenterService.js';
const segmenterService = new SegmenterService();

export default {
	name: 'GeograficosFormComponent',
	data() {
		return {
			errors: '',
            dialog: false,
			isRequiredFilter: true,
			loading_municipios: false,
			loading_data: false,
			type_of_city:["Zonas Metropolitanas","Conurbación","Centros Urbanos", "No identificado"], // "N/A"
			regions:['Centro Norte','Centro Sur','Este','No identificado','Noreste','Oeste','Sureste','Suroeste'],
			states:[],
			municipios: [],
			clusters:[],
			clusters_grouped_by_type: [],
			division:[],
			regions:[],
			step_data: {
				tab: 'geograficos',
				// states
				NomEnt: [],
				// cities
				NomMun: [],
				type_of_city:[],
				DivCoppel: [],
				RegNielsen:[],

				regions:[],

				estado_category: [],
				municipio_category: [],
				tipociudad_category: [],
				clusters_category: [],
				divisionc_segment: [],
				nielsen_segment: [],
				regionesaim_segment: []
			},
			temp_data : {}
		}
	},
    computed: {
		areAllMunSelected(){
			return this.step_data.NomMun.length === this.municipios.length
		},
		areSomeMunSelected(){
			return this.step_data.NomMun.length > 0 && !this.areAllMunSelected
		},
		iconSelectItemsMun () {
			if (this.areAllMunSelected) return 'mdi-close-box'
			if (this.areSomeMunSelected) return 'mdi-minus-box'
			return 'mdi-checkbox-blank-outline'
		},
	},
    watch: {
		"step_data.type_of_city": function(value) {
			this.step_data.clusters_category = [];
			this.clusters = this.clusters_grouped_by_type
			.filter((item) => {
				return value.includes( item.TipoCluster );
			})
			.map((item)=> item.NomCluster )
		}

	},
	methods: {
        handleNextStep(){
            this.$refs.observer.validate().then( res=> {
				if(res && this.hasSomeInputBeenFilled()){
					this.$store.dispatch('update_step_state', { step2: true })
					this.filter_filled_inputs()
					.then(resolve=>{
						this.$store.dispatch('step2_data',this.temp_data);
						this.$emit('onGoStep', 'form');
					})
					.catch(error=>{
						console.log(error);
					});
				} else {
                    this.dialog = true;
                }
			})
        },
        handlePrevStep(){
            this.$emit('onGoStep', 'menu');
        },
        filter_filled_inputs:  function(){
			this.temp_data = {};  
			return  new Promise((resolve, reject)=>{
				for(const field in this.step_data){
					const val = this.step_data[field];
					if(Array.isArray(val) && val.length>0){
						this.temp_data[field] = val;
					}
					if (typeof val === 'string' && val.length>0)
						this.temp_data[field] = val;
				}
				resolve();
			});
		},
        hasSomeInputBeenFilled() {
            if ( this.step_data.NomMun.length != '' ||
			this.step_data.NomEnt.length != '' || 
			this.step_data.type_of_city.length > 0 ||
			this.step_data.clusters_category.length > 0 ||
			this.step_data.DivCoppel.length > 0 ||
			this.step_data.RegNielsen.length > 0 ) {
				return true;
			}
			return false;
		},
        fetchGeographic:function(city){
			this.loading_data = true;
			segmenterService.geographic()
			.then(response =>{
				//this.clusters = response.data.clusters;
				this.clusters_grouped_by_type = response.data.clusters_grouped_by_type;
				this.regions = response.data.regions;
				this.division = response.data.division;
				this.states = response.data.states;
				this.loading_data = false;


			})
			.catch(error =>{
				this.loading_data = false;
			})
		},
		fetchCity: function(){
			this.municipios = []
			this.step_data.NomMun = [];
			if( this.step_data.NomEnt.length == 0 ) return;
			this.loading_municipios = true
			// ajustar nombre a municipios por estados
			segmenterService.cities_by_states(this.step_data.NomEnt)
			.then(response =>{
				this.municipios = response.data.cities;
				this.loading_municipios = false;
			})
			.catch(error =>{
			})
		},
        toggleMun () {
			this.$nextTick(() => {
				if (this.areAllMunSelected) {
					this.step_data.NomMun = []
				} else {
					this.step_data.NomMun = this.municipios.slice()
				}
			});
		},
	},
    created(){
		this.fetchGeographic();
	},
}
</script>

<style scoped>
    .h-100 {
        height: 100%;
    }
    .h-auto {
        height: calc(100% - 48px);
    }
</style>

