// Datos extraidos de tablas

/**
 * Table: mae_articulos
 * description: agrupamiento de area, departamento, categorias y subcaegoriasa
 * Used in: 
 *  - views/dashboard/casosdeuso/components/ModelosSegmentacionCliente.vue
 *  
 */
const area_dep_cat_subcat_group_array = [
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Juguetes, Bebés y Aparatos de Ejercicio",
      "des_Subcategoria": "Aparatos de Ejercicio"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Juguetes, Bebés y Aparatos de Ejercicio",
      "des_Subcategoria": "Bebes"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Juguetes, Bebés y Aparatos de Ejercicio",
      "des_Subcategoria": "Juguetes"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Juguetes, Bebés y Aparatos de Ejercicio",
      "des_Subcategoria": "Outdoor"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Juguetes, Bebés y Aparatos de Ejercicio",
      "des_Subcategoria": "Salud y Bienestar"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Mejoramiento del Hogar",
      "des_Subcategoria": "Baños"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Mejoramiento del Hogar",
      "des_Subcategoria": "Ferreteria"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Mejoramiento del Hogar",
      "des_Subcategoria": "Focos"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Mejoramiento del Hogar",
      "des_Subcategoria": "Material Electrico"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Mejoramiento del Hogar",
      "des_Subcategoria": "Pintura"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Mejoramiento del Hogar",
      "des_Subcategoria": "Plomeria"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Sin Categoría",
      "des_Subcategoria": "Sin Subcategoría"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Transporte y Movilidad",
      "des_Subcategoria": "Accesorios Motos"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Transporte y Movilidad",
      "des_Subcategoria": "Automotriz"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Transporte y Movilidad",
      "des_Subcategoria": "Bicicletas"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Transporte y Movilidad",
      "des_Subcategoria": "Herramientas"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Transporte y Movilidad",
      "des_Subcategoria": "Motos"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "BICICLETAS/BEBES/AUTOMOTRIZ",
      "des_Categoria": "Transporte y Movilidad",
      "des_Subcategoria": "Movilidad Electrica"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "DOMESTICOS",
      "des_Categoria": "Enseres Domésticos",
      "des_Subcategoria": "Articulos de Oficina"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "DOMESTICOS",
      "des_Categoria": "Enseres Domésticos",
      "des_Subcategoria": "Aseo de Casa"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "DOMESTICOS",
      "des_Categoria": "Enseres Domésticos",
      "des_Subcategoria": "Cuidado Personal"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "DOMESTICOS",
      "des_Categoria": "Enseres Domésticos",
      "des_Subcategoria": "Domesticos"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "DOMESTICOS",
      "des_Categoria": "Enseres Domésticos",
      "des_Subcategoria": "Electrodomesticos"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "DOMESTICOS",
      "des_Categoria": "Enseres Domésticos",
      "des_Subcategoria": "Equipaje"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "DOMESTICOS",
      "des_Categoria": "Enseres Domésticos",
      "des_Subcategoria": "Mascotas"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "DOMESTICOS",
      "des_Categoria": "Enseres Domésticos",
      "des_Subcategoria": "Ofertas"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "DOMESTICOS",
      "des_Categoria": "Enseres Domésticos",
      "des_Subcategoria": "Outdoor"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "DOMESTICOS",
      "des_Categoria": "Enseres Domésticos",
      "des_Subcategoria": "Planchado"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "DOMESTICOS",
      "des_Categoria": "Sin Categoría",
      "des_Subcategoria": "Sin Subcategoría"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Celulares",
      "des_Subcategoria": "Accesorios"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Celulares",
      "des_Subcategoria": "Chips Celulares"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Celulares",
      "des_Subcategoria": "Lineas para Venta"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Celulares",
      "des_Subcategoria": "Promociones"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Celulares",
      "des_Subcategoria": "Recargas de Tiempo Aire"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Celulares",
      "des_Subcategoria": "Telefonos Celulares"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Línea Blanca",
      "des_Subcategoria": "Línea Blanca"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Sin Categoría",
      "des_Subcategoria": "Sin Subcategoría"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Tecnologia y Entretenimiento",
      "des_Subcategoria": "Audio"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Tecnologia y Entretenimiento",
      "des_Subcategoria": "Audio Personal"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Tecnologia y Entretenimiento",
      "des_Subcategoria": "Car Audio"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Tecnologia y Entretenimiento",
      "des_Subcategoria": "Cámaras"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Tecnologia y Entretenimiento",
      "des_Subcategoria": "Cómputo"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Tecnologia y Entretenimiento",
      "des_Subcategoria": "Energia"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Tecnologia y Entretenimiento",
      "des_Subcategoria": "Exclusivos BanCoppel"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Tecnologia y Entretenimiento",
      "des_Subcategoria": "Instrumentos Musicales"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Tecnologia y Entretenimiento",
      "des_Subcategoria": "Tarjetas de Contenido"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Tecnologia y Entretenimiento",
      "des_Subcategoria": "Telefonos Fijos"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Tecnologia y Entretenimiento",
      "des_Subcategoria": "Video"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "ELECTRONICA",
      "des_Categoria": "Tecnologia y Entretenimiento",
      "des_Subcategoria": "VideoJuegos"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "JOYERIA",
      "des_Categoria": "Joyería, Relojería y Accesorios",
      "des_Subcategoria": "Joyería"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "LINEA BLANCA",
      "des_Categoria": "Línea Blanca",
      "des_Subcategoria": "Clima y Ventilación"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "LINEA BLANCA",
      "des_Categoria": "Línea Blanca",
      "des_Subcategoria": "Línea Blanca"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "MUEBLE SUELTO",
      "des_Categoria": "Hogar",
      "des_Subcategoria": "Comedor"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "MUEBLE SUELTO",
      "des_Categoria": "Hogar",
      "des_Subcategoria": "Muebles de Cocina"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "MUEBLE SUELTO",
      "des_Categoria": "Hogar",
      "des_Subcategoria": "Muebles de Jardín"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "MUEBLE SUELTO",
      "des_Categoria": "Hogar",
      "des_Subcategoria": "Salas"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "MUEBLE SUELTO",
      "des_Categoria": "Recamara",
      "des_Subcategoria": "Baño"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "MUEBLE SUELTO",
      "des_Categoria": "Recamara",
      "des_Subcategoria": "Bebes"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "MUEBLE SUELTO",
      "des_Categoria": "Recamara",
      "des_Subcategoria": "Camas"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "MUEBLE SUELTO",
      "des_Categoria": "Recamara",
      "des_Subcategoria": "Colchones"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "MUEBLE SUELTO",
      "des_Categoria": "Recamara",
      "des_Subcategoria": "Muebles para Recamara"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "MUEBLE SUELTO",
      "des_Categoria": "Recamara",
      "des_Subcategoria": "Oficina"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "OPTICA",
      "des_Categoria": "Óptica",
      "des_Subcategoria": "Accesorios Óptica"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "OPTICA",
      "des_Categoria": "Óptica",
      "des_Subcategoria": "Lente solar"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "OPTICA",
      "des_Categoria": "Óptica",
      "des_Subcategoria": "Lente solar E-commerce"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "OPTICA",
      "des_Categoria": "Óptica",
      "des_Subcategoria": "Lentes de contacto"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "OPTICA",
      "des_Categoria": "Óptica",
      "des_Subcategoria": "Oftalmico"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "OPTICA",
      "des_Categoria": "Óptica",
      "des_Subcategoria": "Vista cansada"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "RELOJERIA",
      "des_Categoria": "Joyería, Relojería y Accesorios",
      "des_Subcategoria": "Relojeria"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "SALAS, RECAMARAS, COMEDORES",
      "des_Categoria": "Hogar",
      "des_Subcategoria": "Blancos"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "SALAS, RECAMARAS, COMEDORES",
      "des_Categoria": "Hogar",
      "des_Subcategoria": "Comedor"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "SALAS, RECAMARAS, COMEDORES",
      "des_Categoria": "Hogar",
      "des_Subcategoria": "Decoración"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "SALAS, RECAMARAS, COMEDORES",
      "des_Categoria": "Hogar",
      "des_Subcategoria": "Salas"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "SALAS, RECAMARAS, COMEDORES",
      "des_Categoria": "Recamara",
      "des_Subcategoria": "Blancos"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "SALAS, RECAMARAS, COMEDORES",
      "des_Categoria": "Recamara",
      "des_Subcategoria": "Maquinas de Coser"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "SALAS, RECAMARAS, COMEDORES",
      "des_Categoria": "Recamara",
      "des_Subcategoria": "Muebles para Recamara"
    },
    {
      "des_Area": "Muebles",
      "des_Departamento": "SALAS, RECAMARAS, COMEDORES",
      "des_Categoria": "Sin Categoría",
      "des_Subcategoria": "Sin Subcategoría"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "BBS",
      "des_Categoria": "Bebes",
      "des_Subcategoria": "Bebes"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "BBS",
      "des_Categoria": "Niñas",
      "des_Subcategoria": "Niñas"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "BLANCOS",
      "des_Categoria": "Blancos",
      "des_Subcategoria": "Blancos"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "BLANCOS",
      "des_Categoria": "Mascotas",
      "des_Subcategoria": "Mascotas"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "CABALLEROS",
      "des_Categoria": "Caballero Exterior",
      "des_Subcategoria": "Caballero Exterior"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "CABALLEROS",
      "des_Categoria": "Caballeros Accesorios",
      "des_Subcategoria": "Caballeros Accesorios"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "CABALLEROS",
      "des_Categoria": "Deportivo",
      "des_Subcategoria": "Deportivo"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "CABALLEROS",
      "des_Categoria": "Interior y Calceteria",
      "des_Subcategoria": "Interior y Calceteria"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "DAMAS",
      "des_Categoria": "Dama Joven",
      "des_Subcategoria": "Dama Joven"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "DAMAS",
      "des_Categoria": "Dama Señora",
      "des_Subcategoria": "Dama Señora"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "DAMAS",
      "des_Categoria": "Deportivo",
      "des_Subcategoria": "Deportivo"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "DAMAS",
      "des_Categoria": "Lencería",
      "des_Subcategoria": "Lencería"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "NIÑAS",
      "des_Categoria": "Escolar",
      "des_Subcategoria": "Escolar"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "NIÑAS",
      "des_Categoria": "Niñas",
      "des_Subcategoria": "Niñas"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "NIÑOS",
      "des_Categoria": "Escolar",
      "des_Subcategoria": "Escolar"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "NIÑOS",
      "des_Categoria": "Niños",
      "des_Subcategoria": "Niños"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "PERF COSM",
      "des_Categoria": "Caballero Exterior",
      "des_Subcategoria": "Caballero Exterior"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "PERF COSM",
      "des_Categoria": "Cuidado Personal",
      "des_Subcategoria": "Cuidado Personal"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "PERF COSM",
      "des_Categoria": "Dama Joven",
      "des_Subcategoria": "Dama Joven"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "PERF COSM",
      "des_Categoria": "Escolar",
      "des_Subcategoria": "Escolar"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "PERF COSM",
      "des_Categoria": "Impulso",
      "des_Subcategoria": "Impulso"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "PERF COSM",
      "des_Categoria": "Interior y Calceteria",
      "des_Subcategoria": "Interior y Calceteria"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "PERF COSM",
      "des_Categoria": "Juguetes",
      "des_Subcategoria": "Juguetes"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "PERF COSM",
      "des_Categoria": "Lencería",
      "des_Subcategoria": "Lencería"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "ZAPATERIA",
      "des_Categoria": "Bebes",
      "des_Subcategoria": "Bebes"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "ZAPATERIA",
      "des_Categoria": "Deportivo",
      "des_Subcategoria": "Deportivo"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "ZAPATERIA",
      "des_Categoria": "Impulso",
      "des_Subcategoria": "Impulso"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "ZAPATERIA",
      "des_Categoria": "Zapato Caballero",
      "des_Subcategoria": "Zapato Caballero"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "ZAPATERIA",
      "des_Categoria": "Zapato Dama",
      "des_Subcategoria": "Zapato Dama"
    },
    {
      "des_Area": "Ropa",
      "des_Departamento": "ZAPATERIA",
      "des_Categoria": "Zapato Infantil",
      "des_Subcategoria": "Zapato Infantil"
    }
];


/**
 * Table: Categorize_RFM
 * description: agrupamiento de area y categorias
 * Used in: 
 *  - views/dashboard/casosdeuso/components/ModelosSegmentacionCliente.vue
 *  Notes: Solo la primer letra de la categoria es mayuscula
 * 
 */
const area_category_group_array = [
    {
        "des_area": "Ropa",
        "des_categoria": "Bebes"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Blancos"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Caballero exterior"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Caballeros accesorios"
    },
    {
        "des_area": "Muebles",
        "des_categoria": "Celulares"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Cuidado personal"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Dama joven"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Dama señora"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Deportivo"
    },
    {
        "des_area": "Muebles",
        "des_categoria": "Enseres domésticos"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Escolar"
    },
    {
        "des_area": "Muebles",
        "des_categoria": "Hogar"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Impulso"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Interior y calceteria"
    },
    {
        "des_area": "Muebles",
        "des_categoria": "Joyería y relojería"
    },
    {
        "des_area": "Muebles",
        "des_categoria": "Joyería, relojería y accesorios"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Juguetes"
    },
    {
        "des_area": "Muebles",
        "des_categoria": "Juguetes, bebés y aparatos de ejercicio"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Lencería"
    },
    {
        "des_area": "Muebles",
        "des_categoria": "Línea blanca"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Mascotas"
    },
    {
        "des_area": "Muebles",
        "des_categoria": "Mejoramiento del hogar"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Niñas"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Niños"
    },
    {
        "des_area": "Muebles",
        "des_categoria": "Recamara"
    },
    {
        "des_area": "Muebles",
        "des_categoria": "Tecnologia y entretenimiento"
    },
    {
        "des_area": "Muebles",
        "des_categoria": "Transporte y movilidad"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Zapato caballero"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Zapato dama"
    },
    {
        "des_area": "Ropa",
        "des_categoria": "Zapato infantil"
    },
    {
        "des_area": "Muebles",
        "des_categoria": "Óptica"
    }
]

export {
  area_category_group_array,
  area_dep_cat_subcat_group_array
}