<template lang="pug">
    v-container(fluid class="pa-0 h-auto")
        v-row(justify="center" class="h-100")
            v-col(sm="6" class="py-0 px-5 blue-700")
                h3(class="text-subtitle-1 yellow-300--text mt-4 mb-2") ¿Qué hace? 
                p(class="white--text mb-4") Este modelo clasifica a los clientes de acuerdo al número de compras y tiempo transcurrido entre compras de los últimos 5 años y los divide por estrategia de comunicación y probabilidad de compra.
                h3(class="text-subtitle-1 yellow-300--text mb-2") ¿Qué problema soluciona?
                p(class="white--text") ¿Qué cliente me comprara hoy? Permite identificar al cliente con más probabilidades de hacer una compra en ese momento, sin importar la estrategia de campaña, categoría, o tipo de producto. 

            v-col(sm="6" class="align-self-stretch" class="pl-3 pr-6" style="background: rgba(221, 221, 221, 0.5);")
                ValidationObserver( ref="observer")

                    v-card(class="mb-5")
                        v-card-text
                            v-row
                                v-col(sm="12")
                                    p(class="mb-0") Elige las variables del modelo SPC "Probabilidad de compra" mas adecuadas a tu campaña.
                                v-col(sm="6")
                                    p(class="text-subtitle-1 blue-600--text") Retencion del cliente
                                    
                                    v-checkbox(
                                            v-for="(item,index) of spc_retention"
                                            :key="`retention-${index}`"
                                            :label="item.text"
                                            v-model="step_data.spc"
                                            outlined
                                            :value="item.value")
                                v-col(sm="6")
                                    p(class="text-subtitle-1 blue-600--text") Recuperacion del cliente
                                    v-checkbox(
                                            v-for="(item,index) of spc_recovery"
                                            :key="`recovery-${index}`"
                                            :label="item.text"
                                            v-model="step_data.spc"
                                            outlined
                                            :error-messages="errors"
                                            :value="item.value")
                    
                v-row(class="mt-5")
                    v-col(class="d-flex")
                        v-btn(color='grey' dark  tile elevation @click='handlePrevStep')  Regresar
                        v-spacer
                        v-btn( color="primary" @click="handleNextStep"  tile elevation="0") Siguiente

        v-dialog( v-model="dialog" max-width="290" )
            v-card
                v-card-title( class="headline" ) ¡Aviso!
                v-card-text 
                    p( class="black-500--text ") 
                        | Para continuar es necesario haber seleccionado al menos un campo del formulario.
                v-card-actions
                    v-spacer
                    v-btn( color="blue darken-1" text @click="dialog = false") Cerrar

</template>

<script>

export default {
	name: 'SPCFormComponent',
	data() {
		return {
			errors: '',
            dialog: false,
            spc_retention : [{text:'Alta probabilidad de compra',value:'C1'},{text:'Baja probabilidad de compra',value:'C2'}],
			spc_recovery  : [{text:'Alta probabilidad de compra',value:'C3'},{text:'Baja probabilidad de compra',value:'C4'}],
            step_data :{
                segment: 'spc',
                spc: [],
			},
			temp_data : {},
		}
	},
	methods: {
        handleNextStep(){
            this.$refs.observer.validate().then( res=> {
				if(res && this.hasSomeInputBeenFilled()){
					this.$store.dispatch('update_step_state', { step1: true })
					this.filter_filled_inputs()
					.then(resolve=>{
						this.$store.dispatch('step1_data',this.temp_data);
						this.$emit('onGoStep', 'form');
					})
					.catch(error=>{
						console.log(error);
					});
				} else {
					this.dialog = true;
                }
			})
        },
        handlePrevStep(){
            this.$emit('onGoStep', 'menu');
        },
        filter_filled_inputs:  function(){
			this.temp_data = {};
			return  new Promise((resolve, reject)=>{
				const segment = this.step_data.segment
				if( segment != "")
					this.temp_data.segment = segment;
				if(this.step_data["segment"]=="spc"){
					const val = this.step_data.spc;
					if(Array.isArray(val) && val.length>0)
						this.temp_data["spc"] = val;
				}
				resolve();
			})
		},
        hasSomeInputBeenFilled() {
            if( this.step_data.segment == 'spc' && this.step_data.spc.length > 0  ) {
				return true;
			}
			return false;
		}
	},
}
</script>

<style scoped>
    .h-100 {
        height: 100%;
    }
    .h-auto {
        height: calc(100% - 48px);
    }
</style>

