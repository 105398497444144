import { render, staticRenderFns } from "./CompraDetalleFormComponent.vue?vue&type=template&id=580e3f92&scoped=true&lang=pug&"
import script from "./CompraDetalleFormComponent.vue?vue&type=script&lang=js&"
export * from "./CompraDetalleFormComponent.vue?vue&type=script&lang=js&"
import style0 from "./CompraDetalleFormComponent.vue?vue&type=style&index=0&id=580e3f92&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "580e3f92",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRangeSlider } from 'vuetify/lib/components/VRangeSlider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VContainer,VDatePicker,VDialog,VMenu,VRangeSlider,VRow,VSelect,VSpacer,VTextField})
