<template lang="pug">
    v-container(fluid class="pa-0 h-auto")
        v-row(justify="center" class="h-100")
            v-col(sm="4" class="py-0 px-5 blue-700")
                h3(class="text-subtitle-1 yellow-300--text mt-4 mb-2") ¿Qué hace? 
                p(class="white--text mb-4") descripcion descripcion descripcion
                h3(class="text-subtitle-1 yellow-300--text mb-2") ¿Qué problema soluciona?
                p(class="white--text") descripcion descripcion descripcion

            v-col(sm="8" class="align-self-stretch" class="pl-3 pr-6" style="background: rgba(221, 221, 221, 0.5);")
                ValidationObserver(ref='observer')
                    v-card(class="mb-4")
                        v-card-text
                            //-v-row( justify="center" align="center")
                                v-col( sm="12")
                                    h4( class="text-center text-h7 blue-500--text") Compras a detalle
                            v-row
                                v-col(cols='12' sm='6' md='4')
                                    v-menu(
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto")
                                        template(v-slot:activator='{ on, attrs }')
                                            p( class="text-title font-weight-bold blue-500--text mt-5") Fecha de orden de compra
                                            v-text-field(
                                                v-model="step_data.fec_fechaorden"
                                                label="Selecciona una fecha o intervalo de fechas"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on")
                                        v-date-picker(
                                            v-model="step_data.fec_fechaorden"
                                            @input="menu = false"
                                            range
                                            @change="fecOrdenCompraDatePicker"
                                            min="2011-12-28"
                                            :max="max_date")

                                v-col(cols='12' sm='6' md='4')
                                    v-menu(
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto")
                                        template(v-slot:activator='{ on, attrs }')
                                            p( class="text-title font-weight-bold blue-500--text mt-5") Fecha de facturación de compra
                                            v-text-field(
                                                v-model="step_data.fec_FechaFacturacion"
                                                label="Selecciona una fecha o intervalo de fechas"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on")
                                        v-date-picker(
                                            v-model="step_data.fec_FechaFacturacion"
                                            @input="menu = false"
                                            @change="fecFacturacionCompraDatePicker"
                                            range
                                            min="2013-01-01"
                                            :max="max_date")

                            v-row

                                v-col(cols='12' md='4')
                                    //- ValidationProvider(v-slot='{ errors }' name='canal abono' rules='required')
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Canal de venta
                                    v-select( 
                                            :items="canalVentaItems" 
                                            label="Selecciona una o varias opciones"
                                            v-model='step_data.des_canalventa'
                                            multiple chips)
                            
                                v-col(cols='12' md='4')
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Tipo de compra
                                    v-select( 
                                            :items="tipoCompraItems" 
                                            label="Selecciona una o varias opciones"
                                            v-model='step_data.des_tipocompra'
                                            multiple chips)
                                
                                v-col(cols='12' md='4')
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Tipo de pago inicial
                                    v-select( 
                                            :items="pagoInicialItems" 
                                            label="Selecciona una o varias opciones"
                                            v-model='step_data.des_pagoinicial'
                                            multiple chips)
                                
                                v-col(cols='12' md='4')
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Dispositivo utilizado
                                    v-select( 
                                            :items="dispositivoUtilizadoItems" 
                                            label="Selecciona una o varias opciones"
                                            v-model='step_data.des_Dispositivo'
                                            multiple chips)
                    v-card(class="mb-4")
                        v-card-text
                            v-row
                                v-col(cols='12' md='4')
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Área de productos
                                    v-select( 
                                            :items="areaItems" 
                                            label="Selecciona una o varias opciones"
                                            v-model='step_data.des_Area'
                                            multiple chips)

                                v-col(cols='12' md='4') 
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Categorías
                                    v-select( 
                                            :items="categoryItems"
                                            label="Selecciona una o varias opciones"
                                            v-model='step_data.des_categoria'
                                            multiple chips)

                                v-col(cols='12' md='4') 
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Subcategorías
                                    v-select( 
                                            :items="subcategoryItems"
                                            label="Selecciona una o varias opciones"
                                            v-model='step_data.des_subcategoria'
                                            multiple chips)
                            v-row 
                                v-col(cols='12' md='6') 
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Departamento
                                    v-select( 
                                            :items="depProductsItems" 
                                            label="Selecciona una o varias opciones"
                                            v-model='step_data.des_departamento'
                                            @blur="onBlurSelectDep"
                                            multiple chips)
                            

                                v-col(cols='12' md='6') 
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Clase
                                    label(class="text-caption") Busca y elige una o varias clases
                                    v-autocomplete(
                                        append-icon=""
                                        append-outer-icon="mdi-magnify"
                                        :loading="loadingInputClase"
                                        v-model="step_data.des_clase"
                                        :items="clasesItems"
                                        chips
                                        label="Busca y elige una o varias clases"
                                        small-chips
                                        clearable
                                        multiple
                                    )
                            v-row 

                                v-col(cols='12' md='4') 
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Marca
                                    label(class="text-caption") Busca y elige una o varias marcas
                                    v-autocomplete(
                                        append-icon=""
                                        append-outer-icon="mdi-magnify"
                                        :loading="loadingInputMarcas"
                                        v-model="step_data.des_marca"
                                        :items="marcasItems"
                                        chips
                                        label="Busca y elige una o varias marcas"
                                        small-chips
                                        clearable
                                        multiple
                                    )

                                v-col(cols='12' md='4') 
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Descripción de artículo
                                    label(class="text-caption") Busca y elige una o varias descripciones
                                    v-autocomplete(
                                        append-icon=""
                                        append-outer-icon="mdi-magnify"
                                        :loading="loadingInputArticulosDesc"
                                        v-model="step_data.des_articulo"
                                        :items="desArticulosItems"
                                        chips
                                        label="Busca y elige una o varias descripciones"
                                        small-chips
                                        clearable
                                        multiple
                                    )

                                v-col(cols='12' md='4') 
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Nombre del artículo
                                    label(class="text-caption") Busca y elige uno o varios nombres
                                    v-autocomplete(
                                        append-icon=""
                                        append-outer-icon="mdi-magnify"
                                        :loading="loadingInputArticulos"
                                        v-model="step_data.nom_articulo"
                                        :items="nomArticulosItems"
                                        chips
                                        label="Busca y elige uno o varios nombres"
                                        small-chips
                                        clearable
                                        multiple
                                    )
                    v-card 
                        v-card-text
                            v-row
                                v-col(cols='12' md='6')
                                        p( class="text-title font-weight-bold blue-500--text mt-5") Cantidad de artículos por orden
                                        label(class="text-caption") Selecciona el intervalo de articulos
                                        p(class="text-caption text-end mt-1") Rango: {{step_data.cantidad_articulos[0]}} - {{step_data.cantidad_articulos[1]}}
                                        v-range-slider(
                                            step='1'
                                            min='0' max='200'
                                            v-model="step_data.cantidad_articulos"
                                            class="mt-10")
                                        
                                v-col(cols='12' md='6')
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Precio contado por artículo (MXN)
                                    label(class="text-caption") Selecciona el intervalo de precio contado
                                    p(class="text-caption text-end mt-1") Rango: {{step_data.precio_articulo_contado[0]}} - {{step_data.precio_articulo_contado[1]}}
                                    v-range-slider(
                                        step='1'
                                        min='0' max='109999'
                                        v-model="step_data.precio_articulo_contado"
                                        class="mt-10")

                                v-col(cols='12' md='6')
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Precio crédito por artículo (MXN)
                                    label(class="text-caption") Selecciona el intervalo del precio credito
                                    p(class="text-caption text-end mt-1") Rango: {{step_data.precio_articulo_credito[0]}} - {{step_data.precio_articulo_credito[1]}}
                                    v-range-slider(
                                        step='1'
                                        min='0' max='189198'
                                        v-model="step_data.precio_articulo_credito"
                                        class="mt-10")
                v-row(class="mt-5")
                    v-col(class="d-flex")
                        v-btn(color='grey' dark  tile elevation @click='handlePrevStep')  Regresar
                        v-spacer
                        v-btn( color="primary" @click="handleNextStep"  tile elevation="0") Siguiente
        v-dialog( v-model="dialog" max-width="290" )
            v-card
                v-card-title( class="headline" ) ¡Aviso!
                v-card-text 
                    p( class="black-500--text ") 
                        | Para continuar es necesario haber seleccionado al menos un campo del formulario.
                v-card-actions
                    v-spacer
                    v-btn( color="blue darken-1" text @click="dialog = false") Cerrar

</template>

<script>
import { arrPlainEquals } from '../../../../utils/helpers'

import  UseCasesService from '../../../../services/UseCasesService';
const useCasesService = new UseCasesService();

import { 
    area_dep_cat_subcat_group_array
} from '../../../../data' 

export default {
	name: 'CompraDetalleFormComponent',
	data() {
		return {
			errors: '',
            dialog: false,
             canalVentaItems: [
                {text:"Coppel.com",value:"COPPEL.COM"},
                {text:"App Coppel",value:"APP COPPEL"},
                {text:"Coppel.com en tienda",value:"COPPEL.COM EN TIENDA"},
                {text:"Mercado Libre",value:"MERCADO LIBRE"},
            ],
            tipoCompraItems: ['Crédito Coppel', 'Venta Empleado', 'Contado'], 
	    	pagoInicialItems: [ 'Con pago inicial', 'Sin pago inicial', 'Contado' ],
            dispositivoUtilizadoItems: [
                {text:"Android",value:"ANDROID"},
                {text:"Desktop",value:"DESKTOP"},
                {text:"IOS",value:"IOS"},
                {text:"Mobile",value:"MOBILE"},
                {text:"Tablet",value:"TABLET"},
                {text:"Sin dato",value:"unidentified"},
            ],
            areaItems: [
                {text:"Muebles",value:"Muebles"},
                {text:"Ropa",value:"Ropa"},
                {text:"Sin dato",value:"unidentified"},
            ],
            categoryItems: [],

            subcategoryItems: [],

            depProductsItems: [],

            //loading
            loadingInputClase: false,
			//loadingInputFamilia: false,
			loadingInputMarcas: false,
			loadingInputArticulosDesc: false,
			loadingInputArticulos: false,

            familyItems: [],
            clasesItems: [],
            marcasItems: [],
            desArticulosItems: [],
            nomArticulosItems: [],

            step_data :{
                segment: 'comprasdetalle',

                fec_FechaFacturacion: [],
                fec_fechaorden: [],
                des_canalventa: [],
                des_Dispositivo: [],
                des_tipocompra: [],
                des_pagoinicial: [],
                des_Area: [],
                des_categoria: [],
                des_subcategoria: [],
                des_departamento: [],
                //des_familia: [],
                des_clase: [],
                des_marca: [],
                des_articulo: [],
                nom_articulo: [],

                cantidad_articulos: [0,0],
                precio_articulo_credito: [0,0],
                precio_articulo_contado: [0,0],

            },
            temp_data : {},
            max_date: new Date().toISOString().substr(0, 10),
		}
	},
    watch:{
        // fill select Categoría
        "step_data.des_Area": function(areas) {
            // reset
            this.step_data.des_categoria = [];
            this.step_data.des_departamento = [];
            // Filter categories by area
            if(areas.length > 0) {
                this.categoryItems = area_dep_cat_subcat_group_array
                .filter(item =>{
                    return areas.includes(item.des_Area);
                })
                .map(item => item.des_Categoria ).sort();

                this.depProductsItems = area_dep_cat_subcat_group_array
                .filter(item => {
                    return areas.includes(item.des_Area);
                })
                .map(item => item.des_Departamento ).sort();
            } else {
                this.loadCategoryList();
                this.loadDepProductsList()
            }
		},
        // fill select Subcategoría de productos
        "step_data.des_categoria": function(categories) {
            // reset
            this.step_data.des_subcategoria = [];
            // Filter subcategory by category
            if(categories.length > 0) {
                this.subcategoryItems = area_dep_cat_subcat_group_array.filter(item =>{
                    return categories.includes( item.des_Categoria)
                }).map(item => item.des_Subcategoria ).sort();
            } else {
                this.loadSubcategoryList()
            }
		},
        "step_data.des_clase": function(clases) {
            // limpiar Familia
            this.familyItems = [];
            this.step_data.des_Familia = [];
        }
    },
	methods: {
        handleNextStep(){
            this.$refs.observer.validate().then( res=> {
				if(res && this.hasSomeInputBeenFilled()){
					this.$store.dispatch('update_step_state', { step1: true })
					this.filter_filled_inputs()
					.then(resolve=>{
						this.$store.dispatch('step1_data',this.temp_data);
						this.$emit('onGoStep', 'form');
					})
					.catch(error=>{
						console.log(error);
					});
				} else {
                    this.dialog = true;
                }
			})
        },
        handlePrevStep(){
            this.$emit('onGoStep', 'menu');
        },
        filter_filled_inputs:  function(){
			this.temp_data = {};
			return  new Promise((resolve, reject)=>{
				this.temp_data.segment = this.step_data.segment;
				for(const field in this.step_data){
                    const val = this.step_data[field];
                    if(Array.isArray(val) && val.length>0 &&  !arrPlainEquals( val, [0,0] )){
                        this.temp_data[field] = val;
                    }
                }
				resolve();
			})
		},
        hasSomeInputBeenFilled() {
            var flag = false;
            for(const field in this.step_data){
                const itemField = this.step_data[field];
                if(Array.isArray(itemField) && itemField.length > 0 && !arrPlainEquals( itemField, [0,0] ) ){
                    flag = true;
                    break;
                }
            }
            return flag;
		},
        init() {
			this.loadingInputClase = true;
			this.loadingInputFamilia = true;
			this.loadingInputMarcas = true;
			this.loadingInputArticulosDesc = true;
			this.loadingInputArticulos = true;

			useCasesService.jsonArticulosClase()
			.then(response => {
				this.clasesItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
				this.loadingInputClase = false;
			})

            useCasesService.jsonArticulosMarca()
			.then(response => {
				this.marcasItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
                this.loadingInputMarcas = false;
			})

            useCasesService.jsonArticulosDescripcion()
			.then(response => {
				this.desArticulosItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
                this.loadingInputArticulosDesc = false;
			})

            useCasesService.jsonArticulosNombre()
			.then(response => {
				this.nomArticulosItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
                this.loadingInputArticulos = false;
			})
		},
        loadCategoryList() {
            this.categoryItems = area_dep_cat_subcat_group_array.map(item => item.des_Categoria ).sort();
        },
        loadSubcategoryList() {
            this.subcategoryItems = area_dep_cat_subcat_group_array.map(item => item.des_Subcategoria ).sort();
        },
        loadDepProductsList() {
            this.depProductsItems = area_dep_cat_subcat_group_array.map(item => item.des_Departamento ).sort();
        },
        onBlurSelectDep() {
            this.loadingInputClase = true;
            this.clasesItems = [];
            this.step_data.des_clase = [];
            let departaments = this.step_data.des_departamento;
            useCasesService.jsonArticulosClase(departaments)
            .then(response => {
                this.clasesItems = response.data.data;
            })
            .catch(errors => {
                console.log(errors)
            })
            .then(()=>{
                this.loadingInputClase = false;
            })
        
        },
        evalRangeDatePicker(range) {
			var _range = Object.assign([], range)
			if ( new Date(_range[0]).getTime() > new Date(_range[1]).getTime() ) {
				const [from, to] = _range;
				_range = [to, from];
			}
			return _range;
		},
        fecOrdenCompraDatePicker: function(range) {
			this.step_data.fec_fechaorden = this.evalRangeDatePicker(range);
            //
            this.step_data.fec_FechaFacturacion = [];
		},
        fecFacturacionCompraDatePicker: function(range) {
			this.step_data.fec_FechaFacturacion = this.evalRangeDatePicker(range);
            //
            this.step_data.fec_fechaorden = [];

		},
	},
    created() {
        this.init();

        // fill select catogories
        this.loadCategoryList();

        // fill select Subcategoría 
        this.loadSubcategoryList()

        // fill Departamentos de productos
        this.loadDepProductsList()
    }
}
</script>

<style scoped>
    .h-100 {
        height: 100%;
    }
    .h-auto {
        height: calc(100% - 48px);
    }
</style>

