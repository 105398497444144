<template lang="pug">
    div(class="yellow-300--text blue-700 px-5 py-2 text-center bar") {{title}}
</template>
<script>

export default {
    name: 'LabelBarTile', 
    props: {
        title: {
            type: String
        }
    }
}
</script>

<style scoped>
    .bar {
        border-radius: 0 0 10px 10px;
        margin: 0 auto;
    }
</style>
