var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 h-auto",attrs:{"fluid":""}},[_c('v-row',{staticClass:"h-100",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"sm":"6"}},[_c('div',[_c('LabelBarTitle',{staticClass:"label-title-bar",attrs:{"title":"Formato de Salida"}}),_c('v-card',{staticClass:"mt-8 mx-7"},[_c('v-card-text',[_c('p',{staticClass:"mb-10"},[_vm._v("Selecciona la información del cliente que necesitas para detonar tu campaña o proyecto. "),_c('br'),_vm._v("El formato del archivo que se exportará es .csv")]),_c('h3',{staticClass:"blue-500--text"},[_vm._v("Datos a exportar")]),_c('ValidationObserver',{ref:"observerinfoCliente"},[_c('ValidationProvider',{attrs:{"name":"Información del cliente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-checkbox',{attrs:{"label":"id cliente","outlined":"","value":"numerocliente"},model:{value:(_vm.step_data.datos_segment),callback:function ($$v) {_vm.$set(_vm.step_data, "datos_segment", $$v)},expression:"step_data.datos_segment"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-checkbox',{attrs:{"label":"Correo electrónico","outlined":"","value":"des_correoelectronico"},model:{value:(_vm.step_data.datos_segment),callback:function ($$v) {_vm.$set(_vm.step_data, "datos_segment", $$v)},expression:"step_data.datos_segment"}})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"12"}},[_c('p',[_c('b',[_vm._v("Nota:")]),_vm._v(" Debido al manejo de información sensible, si requieres otros datos personales del cliente, por favor solicítalo directamente al área comentado tu necesidad y adjuntando la segmentación que creaste. Contacto: rebeca.renedo@coppel.com, yessica.lara@coppel.com ")])]),_c('v-col',{staticClass:"text-end",attrs:{"cols":"12","md":"12"}},_vm._l((errors),function(error){return _c('label',{staticClass:"red--text"},[_vm._v(_vm._s(error))])}),0)],1)]}}])})],1)],1)],1)],1)]),_c('v-col',{staticClass:"align-self-stretch pa-0",staticStyle:{"background":"rgba(221, 221, 221, 0.5)"},attrs:{"sm":"6"}},[_c('LabelBarTitle',{staticClass:"label-title-bar",attrs:{"title":"Formulario"}}),_c('div',{staticClass:"px-8 pt-8"},[_c('v-card',[_c('v-card-text',[_c('h3',{staticClass:"blue-500--text mb-5 text-center"},[_vm._v("Datos de salida y donde enviaremos tu base.")]),_c('ValidationObserver',{ref:"observer"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_vm._v("Tu nombre")]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"","error-messages":errors,"value":"nombre"},model:{value:(_vm.step_data.name),callback:function ($$v) {_vm.$set(_vm.step_data, "name", $$v)},expression:"step_data.name"}})]}}])})],1)],1),_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',[_vm._v("Área a la que perteneces")])]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Área a la que perteneces","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"","error-messages":errors,"value":"Área a la que perteneces"},model:{value:(_vm.step_data.area),callback:function ($$v) {_vm.$set(_vm.step_data, "area", $$v)},expression:"step_data.area"}})]}}])})],1)],1),_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',[_vm._v("¿Para que utilizaras tu segmento o recomendación?")])]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"¿Para que utilizaras tu segmento o recomendación?","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"","error-messages":errors,"value":"¿Para que utilizaras tu segmento o recomendación?"},model:{value:(_vm.step_data.campaign.target),callback:function ($$v) {_vm.$set(_vm.step_data.campaign, "target", $$v)},expression:"step_data.campaign.target"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',[_vm._v("¿En que canal utilizaras el segmento o recomendación?")])]),_c('v-col',{staticClass:"pl-10",attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"canal","rules":{'required': !_vm.step_data.campaign.other_channel_enabled}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._l((_vm.radioOptions),function(n,i){return _c('v-checkbox',{key:i,staticClass:"mt-0",attrs:{"label":("" + n),"value":n},model:{value:(_vm.step_data.campaign.channels),callback:function ($$v) {_vm.$set(_vm.step_data.campaign, "channels", $$v)},expression:"step_data.campaign.channels"}})}),(errors)?_c('div',{staticClass:"text-left py-0 my-0",attrs:{"cols":"12","md":"12"}},_vm._l((errors),function(error){return _c('span',{staticClass:"red--text"},[_vm._v(_vm._s(error))])}),0):_vm._e()]}}])}),_c('v-checkbox',{staticClass:"shrink mr-2 mt-0 d-inline-block",attrs:{"hide-details":""},model:{value:(_vm.step_data.campaign.other_channel_enabled),callback:function ($$v) {_vm.$set(_vm.step_data.campaign, "other_channel_enabled", $$v)},expression:"step_data.campaign.other_channel_enabled"}}),_c('ValidationProvider',{attrs:{"name":"Otro","rules":{'required': _vm.step_data.campaign.other_channel_enabled}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"d-inline-block",attrs:{"disabled":!_vm.step_data.campaign.other_channel_enabled,"error-messages":errors,"label":"Otro"},model:{value:(_vm.step_data.campaign.other_channel),callback:function ($$v) {_vm.$set(_vm.step_data.campaign, "other_channel", $$v)},expression:"step_data.campaign.other_channel"}})]}}])})],1)],1),_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',[_vm._v("¿Podrias compartirnos el nombre de tu campaña o proyecto?")])]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"nombre de tu campaña o proyecto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"","error-messages":errors,"value":"nombre de tu campaña o proyecto"},model:{value:(_vm.step_data.campaign.name),callback:function ($$v) {_vm.$set(_vm.step_data.campaign, "name", $$v)},expression:"step_data.campaign.name"}})]}}])})],1)],1),_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","md":"4"}},[_c('div',[_vm._v("¿Cual es la fecha de inicio de tu campaña o proyecto?. Si aún no la conoces puedes poner una fecha tentativa.")])]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.step_data.campaign.start,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.step_data.campaign, "start", $event)},"update:return-value":function($event){return _vm.$set(_vm.step_data.campaign, "start", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Fecha de inicio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de inicio","error-messages":errors,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.step_data.campaign.start),callback:function ($$v) {_vm.$set(_vm.step_data.campaign, "start", $$v)},expression:"step_data.campaign.start"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"range":"","min":_vm.min_date,"scrollable":""},on:{"change":_vm.inicioDatePicker},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary primary--text"},on:{"click":function($event){_vm.modal = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.date)}}},[_vm._v("OK")])],1)],1)],1)],1),_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',[_vm._v("¿Como quieres nombrar tu archivo?")])]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"archivo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"","error-messages":errors,"value":"archivo"},model:{value:(_vm.step_data.filename),callback:function ($$v) {_vm.$set(_vm.step_data, "filename", $$v)},expression:"step_data.filename"}})]}}])})],1)],1),_c('v-row',[(_vm.show_alert)?_c('v-col',[_c('v-expand-transition',[_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" Procesando... recibirás un correo electrónico con la segmentación. "),_c('v-btn',{attrs:{"color":"blue-700","small":""},on:{"click":_vm.reloadPage}},[_vm._v("Crear otra segmentación")])],1)],1)],1):_vm._e()],1)],1)],1)],1),_c('v-row',{staticClass:"mt-5 mb-5"},[_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"color":"grey","dark":"","tile":"","elevation":""},on:{"click":_vm.handlePrevStep}},[_vm._v(" Anterior")]),_c('v-spacer'),_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","tile":"","elevation":"","loading":_vm.btn_save_loading,"disabled":_vm.btn_save_loading || _vm.show_alert},on:{"click":_vm.sendForm}},[_vm._v(" ¡Listo! Enviar datos ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }