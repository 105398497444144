<template lang="pug">
    v-container(fluid class="pa-0 h-auto")
        v-row(justify="center" class="h-100")
            v-col(sm="4" class="py-0 px-5 blue-700")
                h3(class="text-subtitle-1 yellow-300--text mt-4 mb-2") RFM por categorías
                p(class="white--text") Segmenta a los clientes por categorías de productos así como por su tipo de pago (credito o contado), considerando que tan recientemente o frecuentemente compraron  productos de esa categoría analizando los últimos 2 años del comportamiento de compra del cliente.
                p(class="mb-0 white--text") <span class="font-weight-bold"> Recencia: </span> Tiempo transcurrido desde su última compra.
                p(class="mb-0 white--text") <span class="font-weight-bold"> Frecuencia: </span> Número  de compras.
                p(class=" white--text") <span class="font-weight-bold"> Monetario: </span> Valor de las compras totales del cliente.
                h3(class="text-subtitle-1 yellow-300--text mb-2") ¿Qué problema soluciona?
                p(class="white--text") Ayuda a identificar a los clientes más leales, fieles, constantes o de visita única, con características de compra similares basadas en el ticket, total y número de compras para un tipo de categoría o tipo de compra en específico.

            v-col(sm="8" class="align-self-stretch" class="pl-3 pr-6" style="background: rgba(221, 221, 221, 0.5);")
                ValidationObserver( ref="observer")

                    v-card(class="mb-5")
                        v-card-text
                            v-row
                                v-col(sm="4")
                                    p(class="text-subtitle-1 blue-600--text font-weight-bold mb-0") Área de productos
                                    label(class="mt-2 text-caption") Elige una o varias opciones.
                                    v-checkbox(
                                            label="Muebles"
                                            v-model="step_data.rfm.des_area"
                                            outlined
                                            value="Muebles")
                                    v-checkbox(
                                            label="Ropa"
                                            v-model="step_data.rfm.des_area"
                                            outlined
                                            value="Ropa")
                                v-col(sm="4")
                                    p(class="text-subtitle-1 blue-600--text font-weight-bold mb-0") Categoría de productos
                                    v-select(
                                        label="Selecciona una o varias categorías."
                                        v-model="step_data.rfm.des_categoria"
                                        multiple
                                        small-chips
                                        :items="des_categoria_rfm")
                                v-col(sm="4")
                                    p(class="text-subtitle-1 blue-600--text font-weight-bold mb-0") Tipo de compra
                                    label(class="mt-2 text-caption")  Selecciona uno o varios tipos de compra.
                                    v-checkbox(
                                            label="Contado"
                                            v-model="step_data.rfm.des_tipocompra"
                                            outlined
                                            value="Contado")
                                    v-checkbox(
                                            label="Crédito"
                                            v-model="step_data.rfm.des_tipocompra"
                                            outlined
                                            value="Crédito coppel")
                    v-card(class="")
                        v-card-text
                            v-row
                                v-col(sm="12")
                                    p(class="mb-0") De acuerdo a las categorías que seleccionaste. Elige las variables RFM más adecuadas a tu campaña.  Puedes combinar todas o solo la más relevante para tí. Recuerda que el valor 4 es el más alto.
                                    p(class="mb-0") <b> Ejemplo: </b> Recencia 4 indica que un cliente compró recientemente ese tipo de categoría.  Frecuencia 4: El mayor número de veces que un cliente compra esa categoría. Monetario 4: El cliente gastó más en sus compras.        

                                v-col(sm="4")
                                    p(class="text-subtitle-1 blue-600--text mb-0") R: Recencia
                                    label(class="text-caption") Tiempo transcurrido desde su última compra
                                    
                                    v-checkbox(
                                            v-for="(item,index) of segments"
                                            :key="`recency-${index}`"
                                            :label="''+item"
                                            v-model="step_data.rfm.RecencyVAL"
                                            outlined
                                            :value="item")
                                v-col(sm="4")
                                    p(class="text-subtitle-1 blue-600--text mb-0") F: Frecuencia
                                    label(class="text-caption") Número  de compras.
                                    v-checkbox(
                                        v-for="(item,index) of segments"
                                        :key="`frecuency-${index}`"
                                        :label="''+item"
                                        v-model="step_data.rfm.FrecuencyVAL"
                                        outlined
                                        :value="item")
                                v-col(sm="4")
                                    p(class="text-subtitle-1 blue-600--text mb-0") M: Monetario
                                    label(class="text-caption") Valor de las compras totales del cliente.
                                    v-checkbox(
                                        v-for="(item,index) of segments"
                                        :key="`monetary-${index}`"
                                        :label="''+item"
                                        v-model="step_data.rfm.MonetaryVAL"
                                        outlined
                                        :error-messages="errors"
                                        :value="item")
                v-row(class="mt-5")
                    v-col(class="d-flex")
                        v-btn(color='grey' dark  tile elevation @click='handlePrevStep')  Regresar
                        v-spacer
                        v-btn( color="primary" @click="handleNextStep"  tile elevation="0") Siguiente
        v-dialog( v-model="dialog" max-width="290" )
            v-card
                v-card-title( class="headline" ) ¡Aviso!
                v-card-text 
                    p( class="black-500--text ") 
                        | Para continuar es necesario haber seleccionado al menos un campo del formulario.
                v-card-actions
                    v-spacer
                    v-btn( color="blue darken-1" text @click="dialog = false") Cerrar

</template>

<script>

import { 
    area_category_group_array
} from '../../../../data' 

export default {
	name: 'RFMFormComponent',
	data() {
		return {
			errors: '',
            dialog: false,
            des_categoria_rfm : [],
            segments: [4,3,2,1], // R F M
            step_data :{
                segment: 'rfm',
                rfm: {
                    des_area: [],
                    des_categoria:[],
                    des_tipocompra: [],
                    RecencyVAL: [],
                    FrecuencyVAL: [],
                    MonetaryVAL: [],
                },

			},
			temp_data : {},
		}
	},
    watch: {
        "step_data.rfm.des_area": function(value) {
            // reset
            this.step_data.rfm.des_categoria = [];
            // Filter categories by area
            if(value.includes('Ropa') || value.includes('Muebles')) {
                this.des_categoria_rfm = area_category_group_array.filter(item =>{
                    return value.includes(item.des_area);
                }).map(item => item.des_categoria );
            } else {
                this.des_categoria_rfm = area_category_group_array.map(item => item.des_categoria );
            }
		},
    },
	methods: {
        handleNextStep(){
            this.$refs.observer.validate().then( async res=> {
				if(res && this.hasAnyInputBeenFilled()){
					this.$store.dispatch('update_step_state', { step1: true })
					await this.filter_filled_inputs()
					.then(resolve=>{
						this.$store.dispatch('step1_data',this.temp_data);
						this.$emit('onGoStep', 'form');
					})
					.catch(error=>{
						console.log(error);
					});
				} else {
                    this.dialog = true;
                }
			})
        },
        handlePrevStep(){
            this.$emit('onGoStep', 'menu');
        },
        filter_filled_inputs:  function(){
			this.temp_data = {};
			return  new Promise((resolve, reject)=>{
				
				const segment = this.step_data.segment
				if( segment != "")
					this.temp_data.segment = segment;
				if(segment=="rfm"){
                    var fields_obj = this.step_data[ segment ];
					for(const field in fields_obj) {
						const val = fields_obj[field];
						if(Array.isArray(val) && val.length>0){
							this.temp_data[field] = val;
						}
					}
				}
				resolve();
			})
		},
        hasAnyInputBeenFilled() {
            const segment = this.step_data.segment
            var flag = false;
            if(segment=="rfm"){
                var fields_obj = this.step_data[ segment ];
                for(const field in fields_obj) {
                    const val = fields_obj[field];
                    if(Array.isArray(val) && val.length>0){
                        flag = true;
                        break;
                    }
                }
                return flag;
            }
			return false;
		}
	},
    created() {
        // fill select categories RFM
        this.des_categoria_rfm = area_category_group_array.map(item => item.des_categoria );

	},
}
</script>

<style scoped>
    .g-buttons {
        max-width: 570px;
    }
    .h-100 {
        height: 100%;
    }
    .h-auto {
        height: calc(100% - 48px);
    }
</style>

