<template lang="pug">
    v-container(fluid class="pa-0 h-auto")
        v-row(justify="center" class="h-100")
            v-col(sm="4" class="py-0 px-5 blue-700")
                h3(class="text-subtitle-1 yellow-300--text mt-4 mb-2") ¿Qué hace?
                p(class="white--text mb-4") Es una base de datos que incluye los datos de los productos buscados por cliente en los últimos 3 meses dentro de la app Coppel.Esta base nos permite realizar agrupaciones de los clientes que buscaron algún producto por área, familia, categoria, marca, etc
                h3(class="text-subtitle-1 yellow-300--text mb-2") ¿Qué problema soluciona?
                p(class="white--text") Permite identificar a los clientes con un interés de búsqueda en algún artículo en especial y así elaborar campañas comerciales con estrategia de ventas focalizadas al producto, marca, categoría etc. Incluso es posible identificar qué artículos son los más buscados en la app para realizar campañas de las busquedas mas relevantes.
            v-col(sm="8" class="align-self-stretch" class="pl-3 pr-6" style="background: rgba(221, 221, 221, 0.5);")
                ValidationObserver( ref="observer")

                    v-card(class="mb-5")
                        v-card-text
                            v-row
                                v-col(cols='12' md='4')
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Área productos
                                    v-select( 
                                            :items="areaItems" 
                                            label="Selecciona una o varias opciones"
                                            v-model='step_data.baseBuscadorApp.des_Area'
                                            multiple chips)

                                v-col(cols='12' md='4') 
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Categoría de productos
                                    v-select( 
                                            :items="categoryItems"
                                            label="Selecciona una o varias opciones"
                                            v-model='step_data.baseBuscadorApp.des_Categoria'
                                            multiple chips)

                                v-col(cols='12' md='4') 
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Subcategoría de productos
                                    v-select( 
                                            :disabled="step_data.baseBuscadorApp.des_Categoria.length == 0"
                                            :items="subcategoryItems"
                                            label="Selecciona una o varias opciones"
                                            v-model='step_data.baseBuscadorApp.des_Subcategoria'
                                            multiple chips)
                                v-row
                                v-col(cols='12' md='4') 
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Departamento de productos
                                    v-select( 
                                            :items="depProductsItems" 
                                            label="Selecciona una o varias opciones"
                                            v-model='step_data.baseBuscadorApp.des_Departamento'
                                            @blur="onBlurSelectDep"
                                            multiple chips) 

                                v-col(cols='12' md='4') 
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Clase
                                    label(class="text-caption") Busca y elige una o varias clases
                                    v-autocomplete(
                                        append-icon=""
                                        append-outer-icon="mdi-magnify"
                                        :loading="loadingInputClase"
                                        v-model="step_data.baseBuscadorApp.des_Clase"
                                        :items="clasesItems"
                                        @blur="onBlurSelectClase"
                                        chips
                                        label="Busca y elige una o varias clases"
                                        small-chips
                                        clearable
                                        multiple
                                    )
                                    
                                v-col(cols='12' md='4') 
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Familia
                                    label(class="text-caption") Busca y elige una o varias familias
                                    v-autocomplete(
                                        :loading="loadingInputFamilia"
                                        v-model="step_data.baseBuscadorApp.des_Familia"
                                        :items="familyItems"
                                        append-icon=""
                                        append-outer-icon="mdi-magnify"
                                        chips
                                        label="Busca y elige una o varias familias"
                                        small-chips
                                        clearable
                                        multiple
                                    )
                                v-row
                                v-col(cols='12' md='4') 
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Marca
                                    label(class="text-caption") Busca y elige una o varias marcas
                                    v-autocomplete(
                                        append-icon=""
                                        append-outer-icon="mdi-magnify"
                                        :loading="loadingInputMarcas"
                                        v-model="step_data.baseBuscadorApp.des_Marca"
                                        :items="marcasItems"
                                        chips
                                        label="Busca y elige una o varias marcas"
                                        small-chips
                                        clearable
                                        multiple
                                    )

                                v-col(cols='12' md='4') 
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Descripción de artículo
                                    label(class="text-caption") Busca y elige una o varias descripciones
                                    v-autocomplete(
                                        append-icon=""
                                        append-outer-icon="mdi-magnify"
                                        :loading="loadingInputArticulosDesc"
                                        v-model="step_data.baseBuscadorApp.des_Articulo"
                                        :items="desArticulosItems"
                                        chips
                                        label="Busca y elige una o varias descripciones"
                                        small-chips
                                        clearable
                                        multiple
                                    )

                                v-col(cols='12' md='4') 
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Nombre del artículo
                                    label(class="text-caption") Busca y elige uno o varios nombres
                                    v-autocomplete(
                                        append-icon=""
                                        append-outer-icon="mdi-magnify"
                                        :loading="loadingInputArticulos"
                                        v-model="step_data.baseBuscadorApp.nom_articulo"
                                        :items="nomArticulosItems"
                                        chips
                                        label="Busca y elige uno o varios nombres"
                                        small-chips
                                        clearable
                                        multiple
                                    )    
                            
                v-row(class="mt-5")
                    v-col(class="d-flex")
                        v-btn(color='grey' dark  tile elevation @click='handlePrevStep')  Regresar
                        v-spacer
                        v-btn( color="primary" @click="handleNextStep"  tile elevation="0") Siguiente
        v-dialog( v-model="dialog" max-width="290" )
            v-card
                v-card-title( class="headline" ) ¡Aviso!
                v-card-text 
                    p( class="black-500--text ") 
                        | Para continuar es necesario haber seleccionado al menos un campo del formulario.
                v-card-actions
                    v-spacer
                    v-btn( color="blue darken-1" text @click="dialog = false") Cerrar

</template>

<script>

import  UseCasesService from '../../../../services/UseCasesService';
const useCasesService = new UseCasesService();

import { 
    area_dep_cat_subcat_group_array,
    area_category_group_array
} from '../../../../data' 

export default {
	name: 'VistasFormComponent',
	data() {
		return {
			errors: '',
            dialog: false,
            // Base búscador App
            areaItems: [
                {text:"Muebles",value:"Muebles"},
                {text:"Ropa",value:"Ropa"},
                //{text:"Sin dato",value:"unidentified"}, si funciona. en db es des_Area IS NULL
            ],
            // jalar de base de datos
            categoryItems: [],
            // jalar de base de datos
            subcategoryItems: [],
            depProductsItems: [],
            familyItems: [],
            clasesItems: [],
            marcasItems: [],
            desArticulosItems: [],
            nomArticulosItems: [],
            //loading
            loadingInputClase: false,
			loadingInputFamilia: false,
			loadingInputMarcas: false,
			loadingInputArticulosDesc: false,
			loadingInputArticulos: false,

            step_data :{
                segment: 'baseBuscadorApp',
                // Búscador App
                baseBuscadorApp: {
                    des_Area: [],
                    des_Categoria: [],
                    des_Subcategoria: [],
                    des_Departamento: [],
                    des_Clase: [],
                    des_Familia: [],
                    des_Marca: [],
                    des_Articulo: [],
                    nom_articulo: []
                },

			},
			temp_data : {},
		}
	},
    watch: {
        // fill select Categoría de productos, Base Buscador App
        "step_data.baseBuscadorApp.des_Area": function(areas) {
            // reset
            this.step_data.baseBuscadorApp.des_Categoria = [];
            this.step_data.baseBuscadorApp.des_Departamento = [];
            // Filter categories by area
            if(areas.length > 0) {
                this.categoryItems = area_dep_cat_subcat_group_array
                .filter(item =>{
                    return areas.includes(item.des_Area);
                })
                .map(item => item.des_Categoria ).sort();

                this.depProductsItems = area_dep_cat_subcat_group_array
                .filter(item => {
                    return areas.includes(item.des_Area);
                })
                .map(item => item.des_Departamento ).sort();
            } else {
                this.loadCategoriesBaseBuscadorApp();
                this.loadDepProducts()
            }
		},
        // fill select Subcategoría de productos, Base Buscador App
        "step_data.baseBuscadorApp.des_Categoria": function(categories) {
            // reset
            this.step_data.baseBuscadorApp.des_Subcategoria = [];
            // Filter subcategory by category
            if(categories.length > 0) {
                this.subcategoryItems = area_dep_cat_subcat_group_array.filter(item =>{
                    return categories.includes( item.des_Categoria)
                }).map(item => item.des_Subcategoria ).sort();
            } else {
                this.loadSubcategoriesBaseBuscadorApp()
            }
		},
        "step_data.baseBuscadorApp.des_Clase": function(clases) {
            // limpiar Familia
            this.familyItems = [];
            this.step_data.baseBuscadorApp.des_Familia = [];
        }
    },
	methods: {
        handleNextStep(){
            this.$refs.observer.validate().then( async res=> {
				if(res && this.hasSomeInputBeenFilled()){
					this.$store.dispatch('update_step_state', { step1: true })
					await this.filter_filled_inputs()
					.then(resolve=>{
						this.$store.dispatch('step1_data',this.temp_data);
						this.$emit('onGoStep', 'form');
					})
					.catch(error=>{
						console.log(error);
					});
				} else {
                    this.dialog = true;
                }
			})
        },
        handlePrevStep(){
            this.$emit('onGoStep', 'menu');
        },
        filter_filled_inputs:  function(){
			this.temp_data = {};
			return  new Promise((resolve, reject)=>{
				
				const segment = this.step_data.segment
				if( segment != "")
					this.temp_data.segment = segment;
				if(segment=="baseBuscadorApp"){
                    var fields_obj = this.step_data[ segment ];
					for(const field in fields_obj) {
						const val = fields_obj[field];
						if(Array.isArray(val) && val.length>0){
							this.temp_data[field] = val;
						}
					}
				}
				resolve();
			})
		},
        hasSomeInputBeenFilled() {
            const segment = this.step_data.segment
            var flag = false;
            if(segment=="baseBuscadorApp"){
                
                var fields_obj = this.step_data[ segment ];
                for(const field in fields_obj) {
                    const val = fields_obj[field];
                    if(Array.isArray(val) && val.length>0){
                        flag = true;
                        break;
                    }
                }
                return flag;
            }
			return false;
		},
        init() {
			this.loadingInputClase = true;
			this.loadingInputFamilia = true;
			this.loadingInputMarcas = true;
			this.loadingInputArticulosDesc = true;
			this.loadingInputArticulos = true;

			useCasesService.jsonArticulosClase()
			.then(response => {
				this.clasesItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
				this.loadingInputClase = false;
			})

            useCasesService.jsonArticulosFamilia()
			.then(response => {
				this.familyItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
                this.loadingInputFamilia = false;
			})

            useCasesService.jsonArticulosMarca()
			.then(response => {
				this.marcasItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
                this.loadingInputMarcas = false;
			})

            useCasesService.jsonArticulosDescripcion()
			.then(response => {
				this.desArticulosItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
                this.loadingInputArticulosDesc = false;
			})

            useCasesService.jsonArticulosNombre()
			.then(response => {
				this.nomArticulosItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
                this.loadingInputArticulos = false;
			})
		},
        loadCategoriesBaseBuscadorApp() {
            this.categoryItems = area_dep_cat_subcat_group_array.map(item => item.des_Categoria ).sort();
        },
        loadSubcategoriesBaseBuscadorApp() {
            this.subcategoryItems = area_dep_cat_subcat_group_array.map(item => item.des_Subcategoria ).sort();
        },
        loadDepProducts() {
            this.depProductsItems = area_dep_cat_subcat_group_array.map(item => item.des_Departamento ).sort();
        },
        onBlurSelectDep() {
            this.loadingInputClase = true;
            this.clasesItems = [];
            this.step_data.baseBuscadorApp.des_Clase = [];
            let departaments = this.step_data.baseBuscadorApp.des_Departamento;
            useCasesService.jsonArticulosClase(departaments)
            .then(response => {
                this.clasesItems = response.data.data;
            })
            .catch(errors => {
                console.log(errors)
            })
            .then(()=>{
                this.loadingInputClase = false;
            })
        
        },
        onBlurSelectClase() {
            this.loadingInputFamilia = true;
            this.familyItems = [];
            this.step_data.baseBuscadorApp.des_Familia = [];
            let clases = this.step_data.baseBuscadorApp.des_Clase;
            useCasesService.jsonArticulosFamilia(clases)
			.then(response => {
				this.familyItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
                this.loadingInputFamilia = false;
			})
        
        },
	},
    created() {
        this.init();

        // fill select catogories Base Buscador App
        this.loadCategoriesBaseBuscadorApp();

        // fill select Subcategoría de productos, Base Buscador App
        this.loadSubcategoriesBaseBuscadorApp()

        // fill Departamentos de productos
        this.loadDepProducts()
	},
}
</script>

<style scoped>
    .h-100 {
        height: 100%;
    }
    .h-auto {
        height: calc(100% - 48px);
    }
</style>

