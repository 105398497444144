<template lang="pug">
    v-container(fluid class="pa-0 h-auto")
        v-row(justify="center" class="h-100")
            v-col(sm="6" class="py-0 px-5 blue-700")
                h3(class="text-subtitle-1 yellow-300--text mt-4 mb-2") ¿Qué hace? 
                p(class="white--text mb-4") Predice y clasifica a un cliente crédito Nuevo dentro de una puntualidad: A, B, C, D o Z y de esta manera no esperar a los meses 6 de maduración del cliente para que sea identificado en un tipo de puntualidad por carteras.
                h3(class="text-subtitle-1 yellow-300--text mb-2") ¿Qué problema soluciona?
                p(class="white--text") Permite identificar las mejores puntualidades de cliente crédito en una etapa temprana para estrategias de abonos o recuperación de crédito y así reducir el costo de inversión en campañas.
 

            v-col(sm="6" class="align-self-stretch" class="pl-3 pr-6" style="background: rgba(221, 221, 221, 0.5);")
                ValidationObserver( ref="observer")

                    v-card(class="mb-5")
                        v-card-text
                            v-row 
                                v-col(class="pb-0")
                                    p(class="text-subtitle-1 blue-600--text") Puntualidad N
                                    p Elige la puntualidad predictiva calculada por el modelo de Puntualidad "N" más adecuadas a tu campaña.

                            v-row( justify="center" align="center")
                                v-col(sm="4" class="" v-for="(item, index) of puntuality" :key="`puntuality-${index}`")
                                    ValidationProvider(
                                        v-slot="{ errors }"
                                        :name="`Cliente `"
                                        rules="required")
                                        v-checkbox(
                                            :label="`Cliente ${item}`"
                                            v-model="step_data.puntuality"
                                            outlined
                                            :error-messages="errors"
                                            :value="item")
                                            
                v-row(class="mt-5")
                    v-col(class="d-flex")
                        v-btn(color='grey' dark  tile elevation @click='handlePrevStep')  Regresar
                        v-spacer
                        v-btn( color="primary" @click="handleNextStep"  tile elevation="0") Siguiente
        
        
        v-dialog( v-model="dialog" max-width="290" )
            v-card
                v-card-title( class="headline" ) ¡Aviso!
                v-card-text 
                    p( class="black-500--text ") 
                        | Para continuar es necesario haber seleccionado al menos un campo del formulario.
                v-card-actions
                    v-spacer
                    v-btn( color="blue darken-1" text @click="dialog = false") Cerrar

</template>

<script>


export default {
	name: 'PuntualityNFormComponent',
	data() {
		return {
			errors: '',
            dialog: false,
            puntuality	  : ['A','B', 'C','D','Z'],
            step_data :{
                segment: 'puntuality',
				puntuality: [],
			},
			temp_data : {},
		}
	},
	methods: {
        handleNextStep(){
            this.$refs.observer.validate().then( res => {
				if(res && this.hasSomeInputBeenFilled()){
					this.$store.dispatch('update_step_state', { step1: true })
					this.filter_filled_inputs()
					.then(resolve=>{
						this.$store.dispatch('step1_data',this.temp_data);
						this.$emit('onGoStep', 'form');
					})
					.catch(error=>{
						console.log(error);
					});
				} else {
                    this.dialog = true;
                }
			})
        },
        handlePrevStep(){
            this.$emit('onGoStep', 'menu');
        },
        filter_filled_inputs:  function(){
			this.temp_data = {};
			return  new Promise((resolve, reject)=>{
                const segment = this.step_data.segment
				if( segment != "")
					this.temp_data.segment = segment;
				if(this.step_data["segment"]=="puntuality"){
					const val = this.step_data.puntuality;
					if(val.length>0)
						this.temp_data["puntuality"] = val;
				}
				resolve();
			})
		},
        hasSomeInputBeenFilled() {
            if( this.step_data.segment == 'puntuality' && this.step_data.puntuality.length > 0 ) {
				return true;
			}
			return false;
		}
	},
}
</script>

<style scoped>
    .h-100 {
        height: 100%;
    }
    .h-auto {
        height: calc(100% - 48px);
    }
</style>

