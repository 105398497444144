<template lang="pug">
    v-container(fluid class="pa-0 h-auto")
        v-row(justify="center" class="h-100")
            v-col(sm="6" class="pa-0")
                div
                    LabelBarTitle(title="Formato de Salida" class="label-title-bar")  
                    v-card(class="mt-8 mx-7")
                        v-card-text()
                            p(class="mb-10") Selecciona la información del cliente que necesitas para detonar tu campaña o proyecto. <br>El formato del archivo que se exportará es .csv
                            h3.blue-500--text Datos a exportar
                            ValidationObserver(ref='observerinfoCliente')
                                ValidationProvider(v-slot='{ errors }' name='Información del cliente' rules='required')
                                    v-row
                                        v-col(cols='12' md='4')
                                            v-checkbox(label='id cliente' v-model='step_data.datos_segment' outlined=''  value='numerocliente')
                                        v-col(cols='12' md='4')
                                            v-checkbox(label='Correo electrónico' v-model='step_data.datos_segment' outlined=''  value='des_correoelectronico')
                                        v-col(cols='12' md='12' class="mt-5")
                                            p <b>Nota:</b> Debido al manejo de información sensible, si requieres otros datos personales del cliente, por favor solicítalo directamente al área comentado tu necesidad y  adjuntando la segmentación que creaste. Contacto: rebeca.renedo@coppel.com, yessica.lara@coppel.com 

                                        v-col(cols='12' md='12' class="text-end")
                                            label(v-for="error of errors " class="red--text") {{error}}

            v-col(sm="6" class="align-self-stretch" class="pa-0" style="background: rgba(221, 221, 221, 0.5);")
                LabelBarTitle(title="Formulario" class="label-title-bar") 
                div(class="px-8 pt-8")
                    //-p(class="text-center") Datos de salida y donde enviaremos tu base.
                    v-card()
                        v-card-text
                            h3(class="blue-500--text mb-5 text-center") Datos de salida y donde enviaremos tu base.
                            ValidationObserver(ref='observer')
                                v-row(class="align-center")
                                    v-col(cols='12' md='4')
                                        | Tu nombre
                                    v-col(cols='12' md='8')
                                        ValidationProvider(v-slot='{ errors }' name='nombre' rules='required')
                                            v-text-field(label='' v-model='step_data.name' :error-messages='errors' value='nombre')
                                v-row(class="align-center")
                                    v-col(cols='12' md='4')
                                        div Área a la que perteneces
                                    v-col(cols='12' md='8')
                                        ValidationProvider(v-slot='{ errors }' name='Área a la que perteneces' rules='required')
                                            v-text-field(label='' v-model='step_data.area' :error-messages='errors' value='Área a la que perteneces')
                                v-row(class="align-center")
                                    v-col(cols='12' md='4')
                                        div ¿Para que utilizaras tu segmento o recomendación?
                                    v-col(cols='12' md='8')
                                        ValidationProvider(v-slot='{ errors }' name='¿Para que utilizaras tu segmento o recomendación?' rules='required')
                                            v-text-field(label='' v-model='step_data.campaign.target' :error-messages='errors' value='¿Para que utilizaras tu segmento o recomendación?')
                                v-row
                                    v-col(cols='12' md='4')
                                        div ¿En que canal utilizaras el segmento o recomendación?
                                    v-col(cols='12' md='8' class="pl-10")
                                        ValidationProvider(v-slot='{ errors }'  name='canal' :rules="{'required': !step_data.campaign.other_channel_enabled}" )
                                            v-checkbox(
                                                class="mt-0"
                                                v-for='(n,i) in radioOptions'
                                                :key='i'
                                                :label='`${n}`'
                                                v-model="step_data.campaign.channels"
                                                :value='n')
                                            div(cols='12' md='12' class="text-left py-0 my-0" v-if="errors") 
                                                span(v-for="error of errors " class="red--text") {{error}}
                                        v-checkbox(
                                            v-model="step_data.campaign.other_channel_enabled"
                                            hide-details
                                            class="shrink mr-2 mt-0 d-inline-block")
                                        ValidationProvider(v-slot='{ errors }' name='Otro' :rules="{'required': step_data.campaign.other_channel_enabled}" )
                                            v-text-field(
                                                :disabled="!step_data.campaign.other_channel_enabled"
                                                v-model='step_data.campaign.other_channel'
                                                :error-messages='errors'
                                                class="d-inline-block"
                                                label="Otro")
                                v-row(class="align-center")
                                    v-col(cols='12' md='4')
                                        div ¿Podrias compartirnos el nombre de tu campaña o proyecto?
                                    v-col(cols='12' md='8')
                                        ValidationProvider(
                                            v-slot='{ errors }' 
                                            name='nombre de tu campaña o proyecto' rules='required')
                                            v-text-field(label='' v-model='step_data.campaign.name'  :error-messages='errors' value='nombre de tu campaña o proyecto')
                                v-row(class="align-center")
                                    v-col( class="text-left" cols='12' md='4')
                                        div ¿Cual es la fecha de inicio de tu campaña o proyecto?. Si aún no la conoces puedes poner una fecha tentativa.
                                    v-col(cols='12' md='8')
                                        v-dialog(
                                            ref="dialog"
                                            v-model="modal"
                                            :return-value.sync="step_data.campaign.start"
                                            persistent
                                            width="290px")
                                            template( v-slot:activator="{ on, attrs }")
                                                ValidationProvider(
                                                    v-slot='{ errors }' 
                                                    name='Fecha de inicio' rules='required')
                                                    v-text-field(
                                                        v-model="step_data.campaign.start"
                                                        label="Fecha de inicio"
                                                        :error-messages='errors'
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    )
                                            v-date-picker(
                                                v-model="date"
                                                range
                                                @change="inicioDatePicker"
                                                :min="min_date"
                                                scrollable)
                                                v-spacer
                                                v-btn(
                                                    text
                                                    color="primary primary--text"
                                                    @click="modal = false") Cancelar
                                                v-btn(
                                                    text
                                                    color="primary"
                                                    @click="$refs.dialog.save(date)") OK
                                v-row(class="align-center")
                                    v-col(cols='12' md='4')
                                        div ¿Como quieres nombrar tu archivo?
                                    v-col(cols='12' md='8')
                                        ValidationProvider(v-slot='{ errors }' name='archivo' rules='required')
                                            v-text-field(label='' v-model='step_data.filename'  :error-messages='errors' value='archivo')
                                v-row
                                    v-col(v-if='show_alert')
                                        v-expand-transition
                                            v-alert(type='success')  Procesando... recibirás un correo electrónico con la segmentación. 
                                                v-btn(@click="reloadPage" color='blue-700' small) Crear otra segmentación
                        
                    v-row(class="mt-5 mb-5")
                        v-col(class="d-flex")
                            v-btn(color='grey' dark  tile elevation @click='handlePrevStep')  Anterior
                            v-spacer
                            v-btn( 
                                color='primary' 
                                class="text-none" 
                                tile 
                                elevation 
                                @click='sendForm' 
                                :loading="btn_save_loading" 
                                :disabled="btn_save_loading || show_alert" )  ¡Listo! Enviar datos                        

</template>

<script>

import moment from 'moment'
import LabelBarTitle from '../components/LabelBarTitle.vue'


export default {
	name: 'FormComponent',
    props: {
        formSelected: {
            type: String,
            require: true
        },
        show_alert:{
			type: Boolean
		},
		btn_save_loading: {
			type: Boolean
		}
    },
	components: {
        LabelBarTitle
	},
	computed: {
	},
	data() {
		return {

            // form
            min_date: new Date().toISOString().substr(0, 10),
			modal: false,
			radioOptions: ['Email', 'SMS', 'Paid Media', 'Venta por Teléfono'],
			date: [],
			formatedDate: null,
			menu: false,
			
			step_data :{
                // step 3
                datos_segment: [],
				// form
				name: '',
				area: '',
				campaign:{
					target: '',
					channels: [],
					other_channel_enabled: false,
					other_channel: '',
					name: '',
					start: [],
				},
				email: '',
				filename: '',
			},
			temp_data : {},
		}
	},
    watch: {
		menu (val) {
			val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
		},

		date (val) {
			this.formatedDate = moment(val).format('DD/MM/YYYY')
		}
	},
	methods: {
        // form
        reloadPage: function() {
			window.location.reload();
		},
		handlePrevStep: function() {
			this.$emit('onGoStep', this.formSelected);
		},
		async sendForm() {
            const responseObserverinfoCliente = await this.$refs.observerinfoCliente.validate();
            if (responseObserverinfoCliente) {
				this.$store.dispatch('step3_data', {"datos_segment": this.step_data.datos_segment});
            } else {
                return;
            }
			const responseObserver = await this.$refs.observer.validate();
			if (responseObserver) {
                this.$store.dispatch('step4_data',this.step_data);
                this.$emit("onSendForm");
            }
		},
		// si la fecha 1 es mayor a la fecha 2 en el rango se invierten los valores
		evalRangeDatePicker(range) {
			var _range = Object.assign([], range)
			if ( new Date(_range[0]).getTime() > new Date(_range[1]).getTime() ) {
				const [from, to] = _range;
				_range = [to, from];
			}
			return _range;
		},
		inicioDatePicker(range) {
			this.date = this.evalRangeDatePicker(range)
		}
	},
}
</script>

<style scoped>
    .g-buttons {
        max-width: 570px;
    }
    .h-100 {
        height: 100%;
    }
    .h-auto {
        height: calc(100% - 48px);
    }
    .label-title-bar{
        max-width: 570px;
    }
</style>

