<template lang="pug">
    v-container(fluid class="pa-0 h-auto")
        v-row(justify="center" class="h-100")
            v-col(sm="4" class="py-0 px-5 blue-700")
                h3(class="text-subtitle-1 yellow-300--text mt-4 mb-2") ¿Qué hace? 
                p(class="white--text mb-4") descripcion descripcion descripcion
                h3(class="text-subtitle-1 yellow-300--text mb-2") ¿Qué problema soluciona?
                p(class="white--text") descripcion descripcion descripcion

            v-col(sm="8" class="align-self-stretch" class="pl-3 pr-6" style="background: rgba(221, 221, 221, 0.5);")
                ValidationObserver(ref='observer')
                    v-card(class="mb-4")
                        v-card-text
                            v-row( justify="center" align="center")
                                v-col( sm="12")
                                    h1( class="text-center text-h6 blue-500--text") Comportamiento de abonos
                                    //-p( class="text-center my-3") Elige el modelo de segmentación de clientes que mas se ajuste a tus necesidades.
                            v-row
                                v-col(cols='12' sm='6' md='4')
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Último movimiento de Abono
                                    v-menu(
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto")
                                        template(v-slot:activator='{ on, attrs }')
                                            //- ValidationProvider(v-slot='{ errors }' name='Fecha del ultimo movimiento de Abono' rules='required')
                                            v-text-field(
                                                v-model="step_data.date_abono"
                                                label="Selecciona una fecha o intervalo de fechas "
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on")
                                        v-date-picker(
                                            v-model="step_data.date_abono"
                                            range
                                            @change="ultimoAbonoDatePicker"
                                            @input="menu = false"
                                            min="2018-01-27"
                                            :max="max_date")
                                v-col(cols='12' md='4')
                                    //- ValidationProvider(v-slot='{ errors }' name='canal abono' rules='required')
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Canal de abono
                                    v-select( 
                                            :items="chanels" 
                                            label="Selecciona una o varias opciones"
                                            v-model='step_data.des_canal'
                                            multiple chips)

                                v-col(cols='12' md='4')
                                    //- ValidationProvider(v-slot='{ errors }' name='canal abono' rules='required')
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Abono a terceros
                                    v-select( 
                                            :items="abonoTerceros" 
                                            label="Selecciona una o opción"
                                            v-model='step_data.email_tercero'
                                            chips)
                                
                                v-col(cols='12' md='4')
                                    //- ValidationProvider(v-slot='{ errors }' name='Importe Abonado' rules='required')
                                    p( class="text-title font-weight-bold blue-500--text mt-5") Importe abonado (MXN)
                                    label(class="text-caption") Selecciona el intervalo del importe que abonó el cliente.
                                    p(class="text-caption text-end mt-1") Rango: {{step_data.importe[0]}} - {{step_data.importe[1]}}
                                    v-range-slider(
                                        step='1'
                                        min='0' max='108820'
                                        v-model="step_data.importe"
                                        class="mt-10")

                v-row(class="mt-5")
                    v-col(class="d-flex")
                        v-btn(color='grey' dark  tile elevation @click='handlePrevStep')  Regresar
                        v-spacer
                        v-btn( color="primary" @click="handleNextStep"  tile elevation="0") Siguiente
        v-dialog( v-model="dialog" max-width="290" )
            v-card
                v-card-title( class="headline" ) ¡Aviso!
                v-card-text 
                    p( class="black-500--text ") 
                        | Para continuar es necesario haber seleccionado al menos un campo del formulario.
                v-card-actions
                    v-spacer
                    v-btn( color="blue darken-1" text @click="dialog = false") Cerrar

</template>

<script>
import { arrPlainEquals } from '../../../../utils/helpers'

import  UseCasesService from '../../../../services/UseCasesService';
const useCasesService = new UseCasesService();

import { 
    area_dep_cat_subcat_group_array
} from '../../../../data' 

export default {
	name: 'AbonosFormComponent',
	data() {
		return {
			errors: '',
            dialog: false,
            abonoTerceros:[
                {text:"Seleccione una opción", value:''},
                {text:"Sí", value:'yes'},
                {text:"No", value:'no'},
                {text:"Todos", value:'all'},
            ],
            chanels:[
                {text:"Coppel.com", value:"APP COPPEL"},
                {text:"App coppel", value:"COPPEL.COM"},
                {text:"Whatsapp", value:"WHATSAPP"},
                {text:"Sin identificar", value:"unidentified"},
            ],
            step_data :{
                segment: 'comportamientoabonos',
                date_abono: [], 
                des_canal: [],
                email_tercero: '',
                importe: [0,0]
            },
            temp_data : {},
            max_date: new Date().toISOString().substr(0, 10),
		}
	},
	methods: {
        handleNextStep(){
            this.$refs.observer.validate().then( res=> {
				if(res && this.hasSomeInputBeenFilled()){
					this.$store.dispatch('update_step_state', { step1: true })
					this.filter_filled_inputs()
					.then(resolve=>{
						this.$store.dispatch('step1_data',this.temp_data);
						this.$emit('onGoStep', 'form');
					})
					.catch(error=>{
						console.log(error);
					});
				} else {
                    this.dialog = true;
                }
			})
        },
        handlePrevStep(){
            this.$emit('onGoStep', 'menu');
        },
        filter_filled_inputs:  function(){
			this.temp_data = {};
			return  new Promise((resolve, reject)=>{
				for(const field in this.step_data){
                    const val = this.step_data[field];
                    if(Array.isArray(val) && val.length>0 && !arrPlainEquals( val, [0,0] ) ){
                        this.temp_data[field] = val;
                    }
                    if ( typeof val == 'string' && val !== '' ) {
                        this.temp_data[field] = val;
                    }
                }
				resolve();
			});
		},
        hasSomeInputBeenFilled() {
            var flag = false;
            for(const field in this.step_data){
                const itemField = this.step_data[field];
                if(Array.isArray(itemField) && itemField.length > 0 && !arrPlainEquals( itemField, [0,0] )){
                    flag = true;
                    break;
                }
            }
            if(this.step_data.email_tercero !== '' || flag) {
                return true;
            }
            return false
		},
        // si la fecha 1 es mayor a la fecha 2 en el rango se invierten los valores
		evalRangeDatePicker(range) {
			var _range = Object.assign([], range)
			if ( new Date(_range[0]).getTime() > new Date(_range[1]).getTime() ) {
				const [from, to] = _range;
				_range = [to, from];
			}
			return _range;
		},
        ultimoAbonoDatePicker: function(range) {
			this.step_data.date_abono = this.evalRangeDatePicker(range);
		},
	},
}
</script>

<style scoped>
    .h-100 {
        height: 100%;
    }
    .h-auto {
        height: calc(100% - 48px);
    }
</style>

