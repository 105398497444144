<template lang="pug">
    v-container(fluid class="pa-0 h-auto")
        v-row(justify="center" class="h-100")
            v-col(sm="6" class="py-0 px-5 blue-700")
                h3(class="text-subtitle-1 yellow-300--text mt-4 mb-2") ¿Qué hace? 
                p(class="white--text mb-4") descripcion descripcion descripcion
                h3(class="text-subtitle-1 yellow-300--text mb-2") ¿Qué problema soluciona?
                p(class="white--text") descripcion descripcion descripcion

            v-col(sm="6" class="align-self-stretch" class="pl-3 pr-6" style="background: rgba(221, 221, 221, 0.5);")
                ValidationObserver( ref="observer")

                    v-card(class="mb-5")
                        v-card-text
                            ValidationObserver(ref='observer')
                                v-row
                                    v-col(cols='12' md='6')
                                        p( class="text-title font-weight-bold blue-500--text mt-5") Meses de la últimade actividad en Coppel
                                        label(class="text-caption") Elige el periodo de la última actividad del cliente en Coppel. (Calculado en meses)
                                        p(class="text-caption text-end") Rango: {{step_data.activity[0]}} - {{step_data.activity[1]}}
                                        v-range-slider(
                                                step='1'
                                                min='0' max='24'
                                                v-model="step_data.activity"
                                                class="mt-2")
                                
                                v-row
                                    v-col(cols='12' sm='6' md='6')
                                        v-menu(
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto")
                                            template(v-slot:activator='{ on, attrs }')
                                                //- ValidationProvider(v-slot='{ errors }' name='Fecha de ultima actividad en ropa' rules='required')
                                                p( class="text-title font-weight-bold blue-500--text mt-5") Última actividad en área ropa
                                                v-text-field(
                                                    v-model="step_data.date_ropa"
                                                    label="Selecciona una fecha o intervalo de fechas"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on")
                                            v-date-picker(
                                                v-model="step_data.date_ropa"
                                                @input="menu = false"
                                                @change="areaRopaDatePicker"
                                                range
                                                min="2014-01-01"
                                                :max="max_date")
                                    v-col(cols='12' sm='6' md='6')
                                        v-menu(
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto")
                                            template(v-slot:activator='{ on, attrs }')
                                                //- ValidationProvider(v-slot='{ errors }' name='Fecha de ultima actividad en Muebles' rules='required')
                                                p( class="text-title font-weight-bold blue-500--text mt-5") Última actividad en área muebles
                                                v-text-field(
                                                    v-model="step_data.date_muebles"
                                                    label="Selecciona una fecha o intervalo de fechas"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on")
                                            v-date-picker(
                                                v-model="step_data.date_muebles"
                                                @input="menu = false"
                                                range
                                                @change="areaMueblesDatePicker"
                                                min="2014-01-01"
                                                :max="max_date")
                                    v-col(cols='12' sm='6' md='6')
                                        v-menu(
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto")
                                            template(v-slot:activator='{ on, attrs }')
                                                //- ValidationProvider(v-slot='{ errors }' name='Fecha de ultima actividad en tiempo Aire' rules='required')
                                                p( class="text-title font-weight-bold blue-500--text mt-5") Última actividad en Tiempo Aire
                                                v-text-field(
                                                    v-model="step_data.date_tiempoaire"
                                                    label="Selecciona una fecha o intervalo de fechas "
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on")
                                            v-date-picker(
                                                v-model="step_data.date_tiempoaire"
                                                @change="tiempoAireDatePicker"
                                                @input="menu = false"
                                                range
                                                min="2020-04-05"
                                                :max="max_date")
                v-row(class="mt-5")
                    v-col(class="d-flex")
                        v-btn(color='grey' dark  tile elevation @click='handlePrevStep')  Regresar
                        v-spacer
                        v-btn( color="primary" @click="handleNextStep"  tile elevation="0") Siguiente
        v-dialog( v-model="dialog" max-width="290" )
            v-card
                v-card-title( class="headline" ) ¡Aviso!
                v-card-text 
                    p( class="black-500--text ") 
                        | Para continuar es necesario haber seleccionado al menos un campo del formulario.
                v-card-actions
                    v-spacer
                    v-btn( color="blue darken-1" text @click="dialog = false") Cerrar

</template>

<script>
import { arrPlainEquals } from '../../../../utils/helpers'


export default {
	name: 'CompraGeneralFormComponent',
	data() {
		return {
			errors: '',
            dialog: false,
            step_data :{
                //type_model: '', //
                segment: 'comprasgenerales',
                activity: [0,0],
                date_ropa: [],
                date_muebles: [],
                date_tiempoaire: [],
            },
            temp_data : {},
            max_date: new Date().toISOString().substr(0, 10),
		}
	},
	methods: {
        handleNextStep(){
            this.$refs.observer.validate().then( res=> {
				if(res && this.hasSomeInputBeenFilled()){
					this.$store.dispatch('update_step_state', { step1: true })
					this.filter_filled_inputs()
					.then(resolve=>{
						this.$store.dispatch('step1_data',this.temp_data);
						this.$emit('onGoStep', 'form');
					})
					.catch(error=>{
						console.log(error);
					});
				} else {
                    this.dialog = true;
                }
			})
        },
        handlePrevStep(){
            this.$emit('onGoStep', 'menu');
        },
        filter_filled_inputs:  function(){
			this.temp_data = {};
			return  new Promise((resolve, reject)=>{
				this.temp_data.segment = this.step_data.segment;
                for(const field in this.step_data){
                    const itemField = this.step_data[field];
                    if(Array.isArray(itemField) && itemField.length > 0 && !arrPlainEquals( itemField, [0,0] )){
                        this.temp_data[field] = itemField;
                    }
                }
				resolve();
			})
		},
        hasSomeInputBeenFilled() {
            var flag = false;
            for(const field in this.step_data){
                const itemField = this.step_data[field];
                if(Array.isArray(itemField) && itemField.length > 0 && !arrPlainEquals( itemField, [0,0] )){
                    flag = true;
                    break;
                }
            }
            return flag;
		},
        evalRangeDatePicker(range) {
			var _range = Object.assign([], range)
			if ( new Date(_range[0]).getTime() > new Date(_range[1]).getTime() ) {
				const [from, to] = _range;
				_range = [to, from];
			}
			return _range;
		},
        areaRopaDatePicker: function(range) {
			this.step_data.date_ropa = this.evalRangeDatePicker(range);
		},
		areaMueblesDatePicker: function(range) {
			this.step_data.date_muebles = this.evalRangeDatePicker(range);
		},
		tiempoAireDatePicker: function(range) {
			this.step_data.date_tiempoaire = this.evalRangeDatePicker(range);
		},
	},
}
</script>

<style scoped>
    .h-100 {
        height: 100%;
    }
    .h-auto {
        height: calc(100% - 48px);
    }
</style>

