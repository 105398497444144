<template lang="pug">
    div(class="h-100")
        bar-header(:title="tileBarHeader")
        v-container(fluid class="pa-0 h-auto" v-if="step == 'menu'")
            v-row(justify="center" class="h-100")
                v-col(sm="6" class="pa-0")
                    LabelBarTitle(title="Modelo" class="label-title-bar")
                    div(class="g-buttons mx-auto mt-8")
                        v-row 
                            v-col
                                v-btn(
                                    block 
                                    small
                                    class="blue-600 black yellow-300--text mb-2"
                                    @click="handleSelectForm('rfm')" ) RFM por Categorías
                            v-col
                                v-btn(
                                    block 
                                    small
                                    class="blue-600 black yellow-300--text mb-2"
                                    @click="handleSelectForm('spc')" ) (SPC) Probabilidad de Compra
                        v-row 
                            v-col
                                v-btn(
                                    block
                                    small
                                    disabled
                                    class="blue-600 black yellow-300--text mb-2") Cliente Cautivo Compras
                            v-col
                                v-btn(
                                    block 
                                    small
                                    class="blue-600 black yellow-300--text mb-2" 
                                    @click="handleSelectForm('puntualityn')" ) Puntualidad N
                        v-row 
                            v-col
                                v-btn(
                                    block 
                                    small
                                    disabled
                                    class="blue-600 black yellow-300--text mb-2") Propensión de compra por sku
                            v-col
                                v-btn(
                                    block 
                                    small
                                    disabled
                                    class="blue-600 black yellow-300--text mb-2" ) Propensión de compra por Marca
                v-col(sm="6" class="pa-0 align-self-stretch"  style="background: rgba(221, 221, 221, 0.4);")
                    LabelBarTitle(title="Tipo de Cliente" class="label-title-bar")
                    div(class="g-buttons mx-auto mt-8")
                        v-row 
                            v-col
                                v-btn(
                                    block 
                                    small
                                    class="blue-600 black yellow-300--text mb-2"
                                    @click="handleSelectForm('tipocliente')") Cliente Coppel 
                            v-col
                                v-btn(
                                    block 
                                    small
                                    class="blue-600 black yellow-300--text mb-2" 
                                    @click="handleSelectForm('geograficos')") Ubicación
                    div(class="rounded-lg yellow-300--text blue-700 px-5 my-4 py-1 text-center text-caption") Comportamiento de Cliente
                    div(class="g-buttons mx-auto")
                        v-row 
                            v-col
                                v-btn(
                                    block 
                                    small
                                    class="blue-600 black yellow-300--text mb-2"
                                    @click="handleSelectForm('comprageneral')") Compra General 
                            v-col
                                v-btn(
                                    block 
                                    small
                                    class="blue-600 black yellow-300--text mb-2" 
                                    @click="handleSelectForm('compradetalle')") Compra a Detalle
                        v-row 
                            v-col
                                v-btn(
                                    block 
                                    small
                                    class="blue-600 black yellow-300--text mb-2"
                                    @click="handleSelectForm('vistasapp')" ) Búsquedas/vistas 
                            v-col
                                v-btn(
                                    block 
                                    small
                                    disabled
                                    class="blue-600 black yellow-300--text mb-2" ) Búsquedas
                        v-row 
                            v-col
                                v-btn(
                                    block 
                                    small
                                    class="blue-600 black yellow-300--text mb-2"
                                    @click="handleSelectForm('comportamientocredito')") Crédito 
                            v-col
                                v-btn(
                                    block 
                                    small
                                    class="blue-600 black yellow-300--text mb-2" 
                                    @click="handleSelectForm('comportamientoabono')") Abono
                        v-row 
                            v-col
                                v-btn(
                                    block 
                                    small
                                    disabled
                                    class="blue-600 black yellow-300--text mb-2") Préstamos 
                            v-col
        RFMFormComponent( v-if="step == 'rfm'" @onGoStep="handleGoStep" )
        SPCFormComponent( v-if="step == 'spc'" @onGoStep="handleGoStep" )
        PuntualityNFormComponent( v-if="step == 'puntualityn'" @onGoStep="handleGoStep" )
        CompraGeneralFormComponent( v-if="step == 'comprageneral'" @onGoStep="handleGoStep" )
        CompraDetalleFormComponent( v-if="step == 'compradetalle'" @onGoStep="handleGoStep" )
        VistasFormComponent( v-if="step == 'vistasapp'" @onGoStep="handleGoStep" )
        CreditoFormComponent( v-if="step == 'comportamientocredito'" @onGoStep="handleGoStep" )
        AbonosFormComponent( v-if="step == 'comportamientoabono'" @onGoStep="handleGoStep" )
        TipoClienteFormComponent( v-if="step == 'tipocliente'" @onGoStep="handleGoStep" )
        GeograficosFormComponent( v-if="step == 'geograficos'" @onGoStep="handleGoStep" )




        FormComponent( 
            v-if="step == 'form'" 
            @onGoStep="handleGoStep" 
            @onSendForm="handleSendForm"
            :show_alert="show_alert"
            :btn_save_loading="btn_save_loading"
            :formSelected="formSelected" )

</template>

<script>

import { mapGetters } from 'vuex';

import BarHeader from '../components/BarHeader'
import RFMFormComponent from '../components/RFMFormComponent'
import SPCFormComponent from '../components/SPCFormComponent'
import PuntualityNFormComponent from '../components/PuntualityNFormComponent'
import CompraGeneralFormComponent from '../components/CompraGeneralFormComponent'
import CompraDetalleFormComponent from '../components/CompraDetalleFormComponent'
import VistasFormComponent from '../components/VistasFormComponent'
import CreditoFormComponent from '../components/CreditoFormComponent'
import AbonosFormComponent from '../components/AbonosFormComponent'
import TipoClienteFormComponent from '../components/TipoClienteFormComponent'
import GeograficosFormComponent from '../components/GeograficosFormComponent'


import FormComponent from '../components/FormComponent'
import LabelBarTitle from '../components/LabelBarTitle'

import UseCasesService from '../../../../services/UseCasesService'
const sseCasesService = new UseCasesService();

export default {
	name: 'Segmentador',

	components: {
		BarHeader,
        RFMFormComponent,
        FormComponent,
        LabelBarTitle,
        SPCFormComponent,
        PuntualityNFormComponent,
        CompraGeneralFormComponent,
        CompraDetalleFormComponent,
        VistasFormComponent,
        CreditoFormComponent,
        AbonosFormComponent,
        TipoClienteFormComponent,
        GeograficosFormComponent
	},
	computed: {
		...mapGetters(['step1', 'step2', 'step3', 'step4', 'stepsState']),
	},
	data() {
		return {
            step: "menu",
            formSelected: '',
            show_alert: false,
            btn_save_loading: false,
            tileBarHeader: 'Segmentos',
			items: [
				{
					id: 1,
					text: 'Generar Segmentos',
					routeName: 'ruform_segments',
				},
				{
					id: 2,
					text: 'Generar Recomendaciones',
					routeName: 'ruform_segments',
				},
				{
					id: 3,
					text: 'Generar Modelos',
					routeName: 'ruform_segments',
				}
			]
		}
	},
	methods: {
        handleSelectForm(form) {
            this.formSelected = form;
            this.updateBarHeader(form);
            this.goToStep(form);
        },
        updateBarHeader(form) {
            switch (form) {
                case 'rfm':
                    this.tileBarHeader = 'Modelos: RFM por Categorías'
                    break;
                case 'spc':
                    this.tileBarHeader = 'Modelos: (SPC) Probabilidad de Compra por Categorías'    
                    break;
                case 'puntualityn':
                    this.tileBarHeader = 'Modelos: Puntualidad N'    
                    break;
                case 'comprageneral':
                    this.tileBarHeader = 'Comportamiento del Cliente: Compra General'    
                    break;
                case 'compradetalle':
                    this.tileBarHeader = 'Comportamiento del Cliente: Compra a Detalle'    
                    break;
                case 'vistasapp':
                    this.tileBarHeader = 'Comportamiento del Cliente: Vistas'    
                    break;
                case 'comportamientocredito':
                    this.tileBarHeader = 'Comportamiento del Cliente: Credito'    
                    break;
                case 'comportamientoabono':
                    this.tileBarHeader = 'Comportamiento del Cliente: Abonos'    
                    break;
                case 'tipocliente':
                    this.tileBarHeader = 'Tipo de cliente: Cliente Coppel'    
                    break;
                case 'geograficos':
                    this.tileBarHeader = 'Tipo de cliente: Ubicación'    
                    break;
                default:
                    this.tileBarHeader = 'Segmentos'
            }
        },
        goToStep(step) {
            window.scrollTo(0, 0)
            this.step = step;
        },
        handleGoStep(step){
            this.goToStep(step);
        },
        handleSendForm() {
            var formData = new FormData();
			if( this.stepsState.step1 ) {
				formData.append("step1", JSON.stringify(this.step1));
			}
            if( this.stepsState.step2 ) {
				formData.append("step2", JSON.stringify(this.step2));
			}
            
			formData.append("step3", JSON.stringify(this.step3));
			formData.append("step4", JSON.stringify(this.step4));
			this.btn_save_loading = true;
			sseCasesService.segmenter(formData)
			.then((response)=>{
				this.show_alert = true;
				this.btn_save_loading = false;
				})
			.catch((error)=>{
				this.btn_save_loading = false;
			})
        }
	},
}
</script>

<style scoped>
    .g-buttons {
        max-width: 570px;
    }
    .h-100 {
        height: 100%;
    }
    .h-auto {
        height: calc(100% - 48px);
    }
    .label-title-bar{
        max-width: 570px;
    }
</style>

